export const Terms = [
    {
      "id": 168,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Leader",
      "plan_id": "Nuskin-Wellcommunity-Leader-USD-Yearly",
      "currency_code": "USD",
      "period_unit": "year",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "590",
      "amount_2": "59",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Leader",
      "trial": "No",
      "status": 1
    },
    {
      "id": 167,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Leader",
      "plan_id": "Nuskin-Wellcommunity-Leader-EUR-Yearly",
      "currency_code": "EUR",
      "period_unit": "year",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "590",
      "amount_2": "59",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Leader",
      "trial": "No",
      "status": 1
    },
    {
      "id": 166,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Leader",
      "plan_id": "Nuskin-Wellcommunity-Leader-EUR-Monthly",
      "currency_code": "EUR",
      "period_unit": "month",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "59",
      "amount_2": "59",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Leader",
      "trial": "No",
      "status": 1
    },
    {
      "id": 165,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Leader",
      "plan_id": "Nuskin-Wellcommunity-Leader-USD-Monthly",
      "currency_code": "USD",
      "period_unit": "month",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "59",
      "amount_2": "59",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Leader",
      "trial": "No",
      "status": 1
    },
    {
      "id": 164,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Business",
      "plan_id": "Nuskin-Wellcommunity-Business-USD-Monthly",
      "currency_code": "USD",
      "period_unit": "month",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "49",
      "amount_2": "49",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Business",
      "trial": "No",
      "status": 1
    },
    {
      "id": 163,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Business",
      "plan_id": "Nuskin-Wellcommunity-Business-EUR-Monthly",
      "currency_code": "EUR",
      "period_unit": "month",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "49",
      "amount_2": "49",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Business",
      "trial": "No",
      "status": 1
    },
    {
      "id": 162,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Business",
      "plan_id": "Nuskin-Wellcommunity-Business-EUR-Yearly",
      "currency_code": "EUR",
      "period_unit": "year",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "490",
      "amount_2": "49",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Business",
      "trial": "No",
      "status": 1
    },
    {
      "id": 161,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Business",
      "plan_id": "Nuskin-Wellcommunity-Business-USD-Yearly",
      "currency_code": "USD",
      "period_unit": "year",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "490",
      "amount_2": "49",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Business",
      "trial": "No",
      "status": 1
    },
    {
      "id": 160,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Starter",
      "plan_id": "Nuskin-Wellcommunity-Starter-USD-Yearly",
      "currency_code": "USD",
      "period_unit": "year",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "290",
      "amount_2": "29",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Basic",
      "trial": "No",
      "status": 1
    },
    {
      "id": 159,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Starter",
      "plan_id": "Nuskin-Wellcommunity-Starter-EUR-Yearly",
      "currency_code": "EUR",
      "period_unit": "year",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "290",
      "amount_2": "29",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Basic",
      "trial": "No",
      "status": 1
    },
    {
      "id": 158,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Starter",
      "plan_id": "Nuskin-Wellcommunity-Starter-USD-Monthly",
      "currency_code": "USD",
      "period_unit": "month",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "29",
      "amount_2": "29",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Basic",
      "trial": "No",
      "status": 1
    },
    {
      "id": 157,
      "subdomain": "nuskin",
      "plan_name": "Nuskin-Wellcommunity-Starter",
      "plan_id": "Nuskin-Wellcommunity-Starter-EUR-Monthly",
      "currency_code": "EUR",
      "period_unit": "month",
      "family_id": "Nuskin-WellCommunity",
      "amount_1": "29",
      "amount_2": "29",
      "platform": "Facebook Instagram",
      "connections": 1,
      "plan_type": "Nuskin-Basic",
      "trial": "No",
      "status": 1
    }
  ]
  
    