import React, { useEffect, useState } from 'react';
import "./basicMonthly.css"
import LocalizationOptions from 'app/shared/JumboCustomizer/components/LocalizationOptions';
// import NovalyaLogo from "../../../logo-aug.png";
import NovalyaLogo from "../../../assets/img/logo-aug.png";
import DcPLanIcon from "../../../assets/img/dc-plans-icon.png";
import DcFreeTrialImg from "../../../assets/img/dc-free-trail.png";
import { useTranslation } from 'react-i18next';
import icon1 from "../../../assets/img/i-icon-01.png"
import icon2 from "../../../assets/img/i-icon-02.png"
import icon3 from "../../../assets/img/i-icon-03.png"
import icon4 from "../../../assets/img/i-icon-04.png"
import icon5 from "../../../assets/img/i-icon-05.png"
import icon6 from "../../../assets/img/i-icon-06.png"
import Vector from "../../../assets/img/Vector-info.png"
import TrailLeft from "../../../assets/img/trail-left.png"
import { useNavigate,useLocation } from 'react-router-dom';
import { Terms } from '../upgradepriceplan/termData';


const BasicMonthly = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [currency, setCurrency] = useState("");
    const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const refId = queryParams.get('uname');

    const handleBuyNow = (planId) => {
        localStorage.setItem("planId", planId);
        localStorage.setItem("fromOffers", "false");
        localStorage.setItem("backto", "/basic-monthly");

        navigate("/signup");
        window.dataLayer.push({ event: "Abonnement" });
    };
    const handleCurreny = async () => {
        const langList = [{ title: "en", value: "en-US" }, { title: "fr", value: "fr-FR" }, { title: "de", value: "de-DE" }, { title: "es", value: "es-ES" }, { title: "pt", value: "pt-PT" }]

        let regionCode = localStorage.getItem("regionCode")
        if (!regionCode) {
            const res = await (await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=2e685b36ba4a4c5cb3645fc6baa56306')).json()

            regionCode = res?.continent_code;
            const lang = res?.languages?.split(",")?.[0]?.split("-")?.[0]

            const selectedLang = langList.find((data) => data?.title.toUpperCase() === lang?.toUpperCase())?.value || "en-US"

            i18n.changeLanguage(lang);
            localStorage.setItem("selectedLocale", selectedLang)
            localStorage.setItem('regionCode', regionCode)
        }

        const isEuropean = regionCode?.toUpperCase() === "EU" ? true : false;

        let country; // remove this if not needed
        if (isEuropean) {
            country = "EUR";
            setCurrency("€");
        } else {
            country = "USD";
            setCurrency("$");
        }
    }
    useEffect(() => {
        refId &&  localStorage.setItem("referralId", refId)
        handleCurreny()
        localStorage.setItem("plans", JSON.stringify(Terms));
    }, [])
    return (
        <main className="BasicPlan">
            <div class="aug-header">
                <div class="dc-container">
                    <div class="aug-hd-logo">
                        <div><img src={NovalyaLogo} /></div>
                        <div style={{ width: "170px" }}><LocalizationOptions /></div>

                    </div>
                </div>
            </div>
            <div className="dc-free-trail">
                <div className="dc-container">
                    <span className="dc-title">
                        {t("pages.title.start-your-free-14-day")} <br /> {t("pages.title.trial-now")}
                    </span>
                    <div className="dc-row">
                        <div className="dc-planLeft">
                            <img src={DcFreeTrialImg} alt="Free Trial" />
                        </div>
                        <div className="dc-planRight" >
                            <img src={DcPLanIcon} alt="Plan Icon" />
                            <span className="dc-plans">{t("pages.title.basic-monthly-plan")}</span>
                            <span className="dc-commit">{t("pages.title.no-commitment")}</span>
                            <span className="dc-priceZero">
                                <span>{`${currency}0`}</span> {t("pages.title.today")}
                            </span>
                            <span className="dc-price">
                                {`${currency}97`} {t("pages.title.per-month-after-14-days-trial")}
                            </span>
                            <ul className="dc-plansList">
                                <li>{t("pages.title.1200-facebook-messages-per-month")}</li>
                                <li>{t("pages.title.800-instagram-messages-per-month")}</li>
                                <li>{t("pages.title.800-ai-credits-per-month")}</li>
                                <li>{t("pages.title.10-tags-pipelines")}</li>
                                <li>{t("pages.title.1-to-1-kickstart-zoom-call")} ({`${currency}60`} {t("pages.title.value")})</li>
                            </ul>
                            <button className="dc-trail-btn" style={{zIndex:"1111"}} onClick={() => handleBuyNow(currency === "$" ? "Basic-Novalya-USD-Monthly" : "Basic-Novalya-EUR-Monthly")} >{t("pages.title.start-free-trial")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <section class="aug-Also-Included-plans" style={{
                width: "100%",
                maxWidth: "1275px",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div class="container">
                    <h1 class="aug-card-tab-hd">{t("pricing.Also Included in all plans")}	</h1>
                    <div class="aug-Also-Included-inner">
                        <div class="aug-Included-inn">
                            <div class="aug-Included-left">
                                <div class="aug-svg-outer">
                                    <img src={icon1} />
                                </div>
                                <span> {t("pricing.Randomize Messages")}</span>
                            </div>
                            <div class="aug-Included-right aug-tooltip-wrap">
                                <img src={Vector} />
                                <div class="aug-tooltiptext">
                                    <h5>{t('pricing_tooltips.title.title20')}</h5>
                                    <p>{t('pricing_tooltips.description.description20')}</p>
                                </div>
                            </div>
                        </div>
                        <div class="aug-Included-inn">
                            <div class="aug-Included-left">
                                <div class="aug-svg-outer">
                                    <img src={icon2} />
                                </div>
                                <span>{t("pricing.Unlimited Contacts")} </span>
                            </div>
                            <div class="aug-Included-right aug-tooltip-wrap">
                                <img src={Vector} />
                                <div class="aug-tooltiptext">
                                    <h5>{t('pricing_tooltips.title.title21')}</h5>
                                    <p>{t('pricing_tooltips.description.description21')}</p>
                                </div>
                            </div>
                        </div>
                        <div class="aug-Included-inn">
                            <div class="aug-Included-left">
                                <div class="aug-svg-outer">
                                    <img src={icon3} />
                                </div>
                                <span>{t("pricing.Tagging Automation")} </span>
                            </div>
                            <div class="aug-Included-right aug-tooltip-wrap">
                                <img src={Vector} />
                                <div class="aug-tooltiptext">
                                    <h5>{t('pricing_tooltips.title.title22')}</h5>
                                    <p>{t('pricing_tooltips.description.description22')}</p>
                                </div>
                            </div>
                        </div>
                        <div class="aug-Included-inn">
                            <div class="aug-Included-left">
                                <div class="aug-svg-outer">
                                    <img src={icon4} />
                                </div>
                                <span>{t("pricing.Don’t Target Same Users")} </span>
                            </div>
                            <div class="aug-Included-right aug-tooltip-wrap">
                                <img src={Vector} />
                                <div class="aug-tooltiptext">
                                    <h5>{t('pricing_tooltips.title.title23')}</h5>
                                    <p>{t('pricing_tooltips.description.description23')}</p>
                                </div>
                            </div>
                        </div>
                        <div class="aug-Included-inn">
                            <div class="aug-Included-left">
                                <div class="aug-svg-outer">
                                    <img src={icon5} />
                                </div>
                                <span>{t("pricing.30+ Messages Templates")} </span>
                            </div>
                            <div class="aug-Included-right aug-tooltip-wrap">
                                <img src={Vector} />
                                <div class="aug-tooltiptext">
                                    <h5>{t('pricing_tooltips.title.title24')}</h5>
                                    <p>{t('pricing_tooltips.description.description25')}</p>
                                </div>
                            </div>
                        </div>
                        <div class="aug-Included-inn">
                            <div class="aug-Included-left">
                                <div class="aug-svg-outer">
                                    <img src={icon6} />
                                </div>
                                <span>{t("pricing.Pipelines Automation")} </span>
                            </div>
                            <div class="aug-Included-right aug-tooltip-wrap">
                                <img src={Vector} alt="" />
                                <div class="aug-tooltiptext">
                                    <h5>{t('pricing_tooltips.title.title25')}</h5>
                                    <p>{t('pricing_tooltips.description.description25')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="aug-14day-trail" style={{
                // paddingTop: "0 20px",
                width: "100%",
                maxWidth: "1275px",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div class="container">
                    <div class="aug-14day-wrap">
                        <img src={TrailLeft} alt="trial Left" />
                        <div class="aug-14day-right">
                            <h3 class="aug-14day-hd">{t("pricing.Start Your")} <span>{t("pricing.14 Days")}</span> {t("pricing.Free Trail")}</h3>
                            <p>{t("pricing.Get your next customers today")} </p>
                            <button class="aug-14trail-btn" onClick={() => handleBuyNow(currency === "$" ? "Basic-Novalya-USD-Monthly" : "Basic-Novalya-EUR-Monthly")}>{t("pricing.Start Free Trial")}</button>
                        </div>
                    </div>
                </div>
                {/* <PlanOfferPOP/> */}
            </section>
            <footer class="aug-plan-footer">
                <div class="container">
                    <div class="aug-plan-footer-inn">
                        <p>Novalya © 2023-2024 &nbsp;&nbsp; | &nbsp;&nbsp; <a href="https://novalya.com/terms-and-conditions/" rel="noreferrer" target="_blank">Terms & Conditions</a></p>
                    </div>
                </div>
            </footer>
        </main>
    );
};

export default BasicMonthly;