export const Terms = [
    {
        "id": 121,
        "subdomain": "app",
        "plan_name": null,
        "plan_id": "Basic-Novalya-USD-Monthly",
        "currency_code": "USD",
        "period_unit": "month",
        "family_id": "Novalya",
        "amount_1": "97",
        "amount_2": "97",
        "plan_type": "Novalya-all",
        "connections": 1,
        "limits": "Basic",
        "trial": "Yes",
        "status": 1
    },
    {
        "id": 123,
        "subdomain": "app",
        "plan_name": null,
        "plan_id": "Business-Novalya-USD-Monthly",
        "currency_code": "USD",
        "period_unit": "month",
        "family_id": "Novalya",
        "amount_1": "197",
        "amount_2": "197",
        "plan_type": "Novalya-all",
        "connections": 2,
        "limits": "Business",
        "trial": "Yes",
        "status": 1
    },
    {
        "id": 125,
        "subdomain": "app",
        "plan_name": null,
        "plan_id": "Unlimited-Novalya-USD-Monthly",
        "currency_code": "USD",
        "period_unit": "month",
        "family_id": "Novalya",
        "amount_1": "297",
        "amount_2": "297",
        "plan_type": "Novalya-all",
        "connections": 2,
        "limits": "Unlimited_new",
        "trial": "Yes",
        "status": 1
    },
    {
        "id": 127,
        "subdomain": "app",
        "plan_name": null,
        "plan_id": "Basic-Novalya-USD-Every-3-months",
        "currency_code": "USD",
        "period_unit": "quater",
        "family_id": "Novalya",
        "amount_1": "231",
        "amount_2": "97",
        "plan_type": "Novalya-all",
        "connections": 1,
        "limits": "Basic",
        "trial": "Yes",
        "status": 1
    },
    {
        "id": 129,
        "subdomain": "app",
        "plan_name": null,
        "plan_id": "Business-Novalya-USD-Every-3-months",
        "currency_code": "USD",
        "period_unit": "quater",
        "family_id": "Novalya",
        "amount_1": "471",
        "amount_2": "197",
        "plan_type": "Novalya-all",
        "connections": 2,
        "limits": "Business",
        "trial": "Yes",
        "status": 1
    },
    {
        "id": 131,
        "subdomain": "app",
        "plan_name": null,
        "plan_id": "Unlimited-Novalya-USD-Every-3-months",
        "currency_code": "USD",
        "period_unit": "quater",
        "family_id": "Novalya",
        "amount_1": "711",
        "amount_2": "297",
        "plan_type": "Novalya-all",
        "connections": 2,
        "limits": "Unlimited_new",
        "trial": "Yes",
        "status": 1
    },
    {
        "id": 133,
        "subdomain": "app",
        "plan_name": null,
        "plan_id": "Basic-Novalya-USD-Yearly",
        "currency_code": "USD",
        "period_unit": "year",
        "family_id": "Novalya",
        "amount_1": "756",
        "amount_2": "97",
        "plan_type": "Novalya-all",
        "connections": 1,
        "limits": "Basic",
        "trial": "Yes",
        "status": 1
    },
    {
        "id": 135,
        "subdomain": "app",
        "plan_name": null,
        "plan_id": "Business-Novalya-USD-Yearly",
        "currency_code": "USD",
        "period_unit": "year",
        "family_id": "Novalya",
        "amount_1": "1536",
        "amount_2": "197",
        "plan_type": "Novalya-all",
        "connections": 2,
        "limits": "Business",
        "trial": "Yes",
        "status": 1
    },
    {
        "id": 137,
        "subdomain": "app",
        "plan_name": null,
        "plan_id": "Unlimited-Novalya-USD-Yearly",
        "currency_code": "USD",
        "period_unit": "year",
        "family_id": "Novalya",
        "amount_1": "2316",
        "amount_2": "297",
        "plan_type": "Novalya-all",
        "connections": 2,
        "limits": "Unlimited_new",
        "trial": "Yes",
        "status": 1
    },


            {
                "id": 122,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Basic-Novalya-EUR-Monthly",
                "currency_code": "EUR",
                "period_unit": "month",
                "family_id": "Novalya",
                "amount_1": "97",
                "amount_2": "97",
                "plan_type": "Novalya-all",
                "connections": 1,
                "limits": "Basic",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 124,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Business-Novalya-EUR-Monthly",
                "currency_code": "EUR",
                "period_unit": "month",
                "family_id": "Novalya",
                "amount_1": "197",
                "amount_2": "197",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 126,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Unlimited-Novalya-EUR-Monthly",
                "currency_code": "EUR",
                "period_unit": "month",
                "family_id": "Novalya",
                "amount_1": "297",
                "amount_2": "297",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 128,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Basic-Novalya-EUR-Every-3-months",
                "currency_code": "EUR",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "231",
                "amount_2": "97",
                "plan_type": "Novalya-all",
                "connections": 1,
                "limits": "Basic",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 130,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Business-Novalya-EUR-Every-3-months",
                "currency_code": "EUR",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "471",
                "amount_2": "197",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 132,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Unlimited-Novalya-EUR-Every-3-months",
                "currency_code": "EUR",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "711",
                "amount_2": "297",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 134,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Basic-Novalya-EUR-Yearly",
                "currency_code": "EUR",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "756",
                "amount_2": "97",
                "plan_type": "Novalya-all",
                "connections": 1,
                "limits": "Basic",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 136,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Business-Novalya-EUR-Yearly",
                "currency_code": "EUR",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "1536",
                "amount_2": "197",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 138,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Unlimited-Novalya-EUR-Yearly",
                "currency_code": "EUR",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "2316",
                "amount_2": "297",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 139,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Business-Novalya-EUR-Every-3-months",
                "currency_code": "EUR",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "471",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "No",
                "status": 1,
                "dueToday":471
            },
            {
                "id": 140,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Business-Novalya-USD-Every-3-months",
                "currency_code": "USD",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "471",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "No",
                "status": 1,
                "dueToday":471
            },
            {
                "id": 141,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Business-Novalya-USD-Monthly",
                "currency_code": "USD",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "471",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "Yes",
                "status": 1,
                "dueToday":236
            },
            {
                "id": 142,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Business-Novalya-EUR-Monthly",
                "currency_code": "EUR",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "471",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "Yes",
                "status": 1,
                "dueToday":236
            },
            {
                "id": 143,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Basic-Novalya-USD-Every-3-months",
                "currency_code": "USD",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "231",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Basic",
                "trial": "No",
                "status": 1,
                "dueToday":97
            },
            {
                "id": 144,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Basic-Novalya-EUR-Every-3-months",
                "currency_code": "EUR",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "231",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Basic",
                "trial": "No",
                "status": 1,
                "dueToday":97
            },
            {
                "id": 145,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Basic-Novalya-USD-Yearly",
                "currency_code": "USD",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "756",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Basic",
                "trial": "No",
                "status": 1,
                "dueToday":397
            },
            {
                "id": 146,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Basic-Novalya-EUR-Yearly",
                "currency_code": "EUR",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "756",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Basic",
                "trial": "No",
                "status": 1,
                "dueToday":397
            },
            {
                "id": 147,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Business-Novalya-USD-Every-3-months",
                "currency_code": "USD",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "471",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "No",
                "status": 1,
                "dueToday":197
            },
            {
                "id": 148,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Business-Novalya-EUR-Every-3-months",
                "currency_code": "EUR",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "471",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "No",
                "status": 1,
                "dueToday":197
            },
            {
                "id": 149,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Business-Novalya-USD-Yearly",
                "currency_code": "USD",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "1536",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "No",
                "status": 1,
                "dueToday":797
            },
            {
                "id": 150,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Business-Novalya-EUR-Yearly",
                "currency_code": "EUR",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "1536",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "No",
                "status": 1,
                "dueToday":797
            },
            {
                "id": 151,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Unlimited-Novalya-USD-Every-3-months",
                "currency_code": "USD",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "711",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "No",
                "status": 1,
                "dueToday":297
            },
            {
                "id": 152,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Unlimited-Novalya-EUR-Every-3-months",
                "currency_code": "EUR",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "711",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "No",
                "status": 1,
                "dueToday":297
            },
            {
                "id": 153,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Unlimited-Novalya-USD-Yearly",
                "currency_code": "USD",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "2316",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "No",
                "status": 1,
                "dueToday":1197
            },
            {
                "id": 154,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Special-Offer-Black-Friday-Unlimited-Novalya-EUR-Yearly",
                "currency_code": "EUR",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "2316",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "No",
                "status": 1,
                "dueToday":1197
            },
            {
                "id": 155,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Formation-Leads-en-RDV-Qualifies-Basic-Plan-EUR-Monthly",
                "currency_code": "EUR",
                "period_unit": "Monthly",
                "family_id": "Novalya",
                "amount_1": "97",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Special Course + Basic Plan",
                "trial": "No",
                "status": 1,
                "dueToday":100
            },
            {
                "id": 156,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Formation-Leads-en-RDV-Qualifies-Basic-Plan-USD-Monthly",
                "currency_code": "USD",
                "period_unit": "Monthly",
                "family_id": "Novalya",
                "amount_1": "97",
                "amount_2": "157",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Special Course + Basic Plan",
                "trial": "No",
                "status": 1,
                "dueToday":100
            },
   
   
]

