import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { countries } from "../../components/mui/AutoCompletes/data";
import {
  registerUser,
  get_user_data_by_referral_id,
  getPlans,
} from "../../../../backendServices/ApiCalls";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useTranslation } from "react-i18next";
import "./signup.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import parsePhoneNumber from "libphonenumber-js";
import { enUS, frFR, esES, srRS } from "@mui/material/locale";
import { MuiTelInput } from "mui-tel-input";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";


const supportedLocales = ["en-US", "fr-FR", "de-DE", "es-ES", "pt-PT"];
const localStorageKey = "selectedLocale";

const updateLocaleInLocalStorage = () => {
  // Get the browser's active language
  const browserLocale = navigator.language || navigator.userLanguage;

  // Check if the browser locale matches one of the supported locales
  if (supportedLocales.includes(browserLocale)) {
    // Get the current value from local storage
    const storedLocale = localStorage.getItem(localStorageKey);

    // If it's not already stored, update the local storage
    if (!storedLocale) {
      localStorage.setItem(localStorageKey, browserLocale);
    }
  }
};

const ResellerSignup = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    firstname: yup
      .string(t("NewSignUpMessages.firstname.string"))
      .required(t("NewSignUpMessages.firstname.required")),
    // username: yup
    //   .string(t("NewSignUpMessages.username.string"))
    //   .matches(/^[a-zA-Z0-9]*$/, t("NewSignUpMessages.username.matches"))
    //   .matches(/^\S*$/, t("NewSignUpMessages.username.matches"))
    //   .min(6, t("NewSignUpMessages.username.min"))
    //   .required(t("NewSignUpMessages.username.required")),
    lastname: yup
      .string(t("NewSignUpMessages.lastname.string"))
      .required(t("NewSignUpMessages.lastname.required")),
    email: yup
      .string(t("NewSignUpMessages.email.string"))
      .email(t("NewSignUpMessages.email.email"))
      .required(t("NewSignUpMessages.email.required")),
    confirm_email: yup
      .string(t("NewSignUpMessages.confirm_email.string"))
      .email(t("NewSignUpMessages.confirm_email.email"))
      .oneOf(
        [yup.ref("email"), null],
        t("NewSignUpMessages.confirm_email.oneOf")
      )
      .required(t("NewSignUpMessages.confirm_email.required")),
    mobile: yup
      .string(t("NewSignUpMessages.mobile.string"))
      .required(t("NewSignUpMessages.mobile.required")),
    address: yup
      .string(t("NewSignUpMessages.address.string"))
      .required(t("NewSignUpMessages.address.required")),
    zipCode: yup
      .string(t("NewSignUpMessages.zipCode.number"))
      .required(t("NewSignUpMessages.zipCode.required")),
    city: yup
      .string(t("NewSignUpMessages.city.string"))
      .required(t("NewSignUpMessages.city.required")),
    country: yup.mixed().required(t("NewSignUpMessages.country.required")),
    password: yup
      .string()
      .required(t("NewSignUpMessages.password.required"))
      .min(8, t("NewSignUpMessages.password.min"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()+^])[A-Za-z\d@$!%*?&()+^]+$/,
        t("NewSignUpMessages.password.matches")
      ),
    confirmpassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        t("NewSignUpMessages.confirmpassword.oneOf")
      )
      .required(t("NewSignUpMessages.confirmpassword.required")),
    checkBox: yup
      .boolean()
      .required()
      .oneOf([true], t("NewSignUpMessages.confirmpassword.checkBox")),
  });

  const { referralid } = useParams();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [sponsorData, setSponsorData] = useState({});
  const [lan, setLan] = useState("en");
  const [phone, setPhone] = React.useState();
  const [referralIdSpinner, setReferralIdSpinner] = useState(true);
  const [defaultCountry, setDefaultCountry] = useState("FR"); // Default to France

  const [loader, setLoader] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const addressRef = useRef(null);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isFromOffers = localStorage.getItem('fromOffers');
  const backTO = localStorage.getItem('backto');

  const [planDetails, setPlanDetails] = useState(null);
  const navigate =useNavigate()
  const courseLimits = ["Formation-Leads-en-RDV-Qualifies-Basic-Plan-EUR-Monthly","Formation-Leads-en-RDV-Qualifies-Basic-Plan-USD-Monthly"]
  const currentPlan = localStorage.getItem("planId");
  if (!currentPlan) {
    navigate("/plans")
  }
  useEffect(() => {
    let checkPlanSelect = localStorage.getItem("planId");
    let plans = localStorage.getItem("plans");
    plans = JSON.parse(plans);
    let selectedPlan = plans?.find((plan) => plan.plan_id === checkPlanSelect);

    setPlanDetails(selectedPlan);

    const handleLoad = (setFieldValue) => {
      const input = document.getElementById("address");
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ["geocode"],
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          return;
        }
        setFieldValue("address", place.formatted_address);

        const addressComponents = place.address_components;
        const city = addressComponents.find((component) =>
          component.types.includes("locality")
        );
        const zipCode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );
        const countrie = addressComponents.find((component) =>
          component.types.includes("country")
        );

        setFieldValue("city", city?.long_name || "");
        setFieldValue("zipCode", zipCode?.long_name || "");
        const selectedCountry = countries.find(
          (country) =>
            country.code.toLowerCase() === countrie?.short_name.toLowerCase()
        );
        setSelectedCountry(selectedCountry || "");
        setFieldValue("country", selectedCountry);
      });

      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          if (data.country_code) {
            setDefaultCountry(data.country_code); // Set country code based on IP address
            const newValue = countries.find(
              (country) => country.code === data.country_code
            );
            setSelectedCountry(newValue);
            setFieldValue("country", newValue);
          }
        })
        .catch((error) => {
          console.error("Error fetching IP address:", error);
        });
    };

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCfYReauVWsdFbjZntfrcPOn4V7VB27WN0&libraries=places`;
    script.async = true;
    script.onload = () => {
      // Using Formik context here to get setFieldValue
      const formikContext =
        addressRef.current?.closest("form")?.__formikContext;
      if (formikContext) {
        handleLoad(formikContext.setFieldValue);
      }
    };
    document.body.appendChild(script);

    setTimeout(() => {
      handleLoad(window.setFieldValue);
    }, 2000);
  }, []);

  useEffect(() => {

    const meta = document.createElement("meta");
    meta.name = "google";
    meta.content = "notranslate";
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);
  useEffect(() => {
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", disableRightClick);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  const initialstates = {
    username: null,
    firstname: "",
    lastname: "",
    email: "",
    confirm_email: "",
    mobile: "",
    company: "",
    address: "",
    zipCode: "",
    city: "",
    country: null,
    language: null,
    password: null,
    confirmpassword: "",
    domain:""
    // birthday: null,
  };
  const handleSubmit = (data, setSubmitting) => {
    const domainParts = window.location.hostname.split(".");
    let domain = domainParts.length > 1 ? domainParts[0] : "";
    domain= domain==="wcy-nuskin"?"nuskin":domain
    let params = {
      sponsorid: sponsorData?.randomcode || "NOVALYA",
      username: data.username,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      mobile: data.mobile,
      address: data.address,
      password: data.password,
      country: selectedCountry?.code,
      language: localStorage.getItem("selectedLocale"),
      company: data.company,
      zipCode: data.zipCode,
      city: data.city,
      item_price_id: localStorage.getItem("planId"),
      coupon_code: "empty",
      domain,
      // birthday: "22-08-1994",
    };

    registerUser(
      params,
      (response) => {
        console.log(response?.data?.status)

        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setSubmitting(false);
          localStorage.setItem("registration_data", JSON.stringify(params));
          window.location.replace(response?.data?.redirect_url);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        setSubmitting(false);
      }
    );
  };

  const setReferralId = () => {
 
    localStorage.setItem("referralId", referralid);
    window.location.href = "https://novalya.com/";
  };


  useEffect(() => {
    { 
      let checkPlanSelect = localStorage.getItem("planId");

      if(referralid){
        setReferralId()
      }else{
        checkPlanSelect === null
        ? navigate("/plans")
        : setReferralIdSpinner(false);
        let storageReferralId = localStorage.getItem("referralId");
        get_user_data_by_referral_id(
          { randomcode: storageReferralId },
          (response) => {
            setLoader(true);
            setSponsorData(response?.data?.data);
            setLoader(false);
            setReferralIdSpinner(false);
  
          },
          (error) => {}
        );
      }

     
    }
    let countryName = "France";
    const country = countries.find(
      (country) => country.label.toLowerCase() === countryName.toLowerCase()
    );
    setSelectedCountry(country);
  }, []);



  let frenchObj = {
    line1: "S'abonner",
    line2: "Déjà un compte ?",
    line3: "Se connecter",
    line4: "Retour en haut de page",
    line5: "abonnement",
    line6: "En partenariat avec",
    line7: "utilisateur: ",
    line8: "poste: ",
    line9:
      "Je confirme par la présente que j'ai plus de 16 ans et j'accepte les termes et conditions.",
    line10: "Adresse de facturation:",
    pline1: "Nom d'utilisateur",
    pline2: "Prénom",
    pline3: "Nom de famille",
    pline4: "Numéro de portable",
    pline5: "Email",
    pline6: "Confirmer l'adresse e-mail",
    pline7: "Nom de la société (facultatif)",
    pline8: "Adresse",
    pline9: "Ville",
    pline10: "Code postal",
    pline11: "Choisir un pays",
    pline12: "Choisir une langue",
    pline13: "Mot de passe",
    pline14: "Confirmer le mot de passe",
  };

  const defautLanguageFun = () => {
    const defaultLanguage = localStorage.getItem("selectedLocale") ;
    const wordsArray = defaultLanguage?.split("-");
    if (wordsArray?.length > 0) {
      setLan(wordsArray[0]);
    }
  };

  useEffect(() => {
    defautLanguageFun();
    updateLocaleInLocalStorage();
  }, []);

  if (loader || referralIdSpinner) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  

  const periodSelector = (planid) => {
    const planidArrb = planid?.split("-")?.reverse();
    let result;

    if (planidArrb?.[0] === "months") {
      result = " quarterly";
    } else if (planidArrb?.[0] === "Monthly") {
      result = " monthly";
    } else {
      result = " yearly";
    }
    return result;
  };

  return (
    <>
    {alertData.show && (
        <SweetAlert
          alertData={alertData}
          setalertData={setalertData}
          sx={{
            background: "linear-gradient(to bottom, #6b8e29 50%,#2a3511 100%)",
          }}
        />
      )}

      {
        !referralIdSpinner &&  <div class="signup-new-wraper">
        <div class="pricing-header">
          <div class="signup-container">
            <img src="assets/images/pricing-logo.png" alt="" />
          </div>
        </div>
        <div class="signup-blue-bar">
          <div class="signup-container">
            <span class="signup-blue-text">
              <span>Novalya :</span>
              {t(
                "pages.title.Streamline Your Lead Generation Process with Social Media Automation"
              )}
            </span>
          </div>
        </div>
        <div class="signup-container">
          <div class="signup-column-row">
            <div class="signup-wraper-left">
              <div class="pricing-left-top-bar">
                <a onClick={()=>backTO ? navigate(backTO): navigate("/plans")} style={{cursor:"pointer"}}>
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="13"
                      cy="13"
                      r="12.5"
                      fill="#2C73FF"
                      stroke="#2C73FF"
                    ></circle>
                    <path
                      d="M14.5293 9.17627L10.7058 12.9998L14.5293 16.8233"
                      stroke="white"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>{" "}
                  {t("pages.title.Back to Plans")}
                </a>
                <span class="sponsered-text">
                  <span> {t("pages.title.Sponsored by")}:</span>
                  {sponsorData?.randomcode
                    ? sponsorData?.randomcode
                    : "NOVALYA"}
                </span>
              </div>
              <span class="title-22 mb-36">
                {t(
                  "pages.title.Join The Entrepreneurs Who Use Novalya To Easily Get Their Next Customers on Social Media"
                )}
              </span>
              <span class="title-20">
                {" "}
                {/* {isFromOffers === 'true'?t("pages.title.30_day_money") : t("pages.title.100% NO-RISK FREE TRIAL")} */}
              </span>
              {courseLimits?.includes(currentPlan)? <ul class="pricing-features-list">
                <li>
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0538 0.69045C13.3073 0.437751 13.6446 0.298049 13.9945 0.300822C14.3444 0.303595 14.6796 0.448626 14.9294 0.705313C15.1792 0.962 15.3239 1.31026 15.3332 1.67662C15.3424 2.04297 15.2154 2.39877 14.979 2.66892L7.80023 12.0713C7.67679 12.2106 7.5278 12.3223 7.36218 12.3999C7.19656 12.4775 7.01771 12.5192 6.83632 12.5228C6.65492 12.5263 6.47471 12.4915 6.30647 12.4204C6.13822 12.3493 5.98539 12.2434 5.85711 12.109L1.09647 7.12329C0.963896 6.99391 0.857561 6.8379 0.783809 6.66455C0.710057 6.4912 0.670399 6.30406 0.667202 6.11432C0.664005 5.92457 0.697335 5.73609 0.765202 5.56012C0.833069 5.38415 0.934083 5.2243 1.06222 5.09011C1.19035 4.95591 1.34299 4.85012 1.51101 4.77905C1.67903 4.70797 1.859 4.67307 2.04018 4.67641C2.22137 4.67976 2.40005 4.7213 2.56557 4.79853C2.7311 4.87577 2.88007 4.98714 3.00361 5.12598L6.77109 9.06972L13.0197 0.731904L13.0538 0.69045Z"
                      fill="#02DF33"
                    />
                  </svg>{" "}
                  {t("pages.title.sign_p1",{currency:(planDetails?.currency_code === "USD" ? "$" : "€")})}
                </li>
                <li>
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0538 0.69045C13.3073 0.437751 13.6446 0.298049 13.9945 0.300822C14.3444 0.303595 14.6796 0.448626 14.9294 0.705313C15.1792 0.962 15.3239 1.31026 15.3332 1.67662C15.3424 2.04297 15.2154 2.39877 14.979 2.66892L7.80023 12.0713C7.67679 12.2106 7.5278 12.3223 7.36218 12.3999C7.19656 12.4775 7.01771 12.5192 6.83632 12.5228C6.65492 12.5263 6.47471 12.4915 6.30647 12.4204C6.13822 12.3493 5.98539 12.2434 5.85711 12.109L1.09647 7.12329C0.963896 6.99391 0.857561 6.8379 0.783809 6.66455C0.710057 6.4912 0.670399 6.30406 0.667202 6.11432C0.664005 5.92457 0.697335 5.73609 0.765202 5.56012C0.833069 5.38415 0.934083 5.2243 1.06222 5.09011C1.19035 4.95591 1.34299 4.85012 1.51101 4.77905C1.67903 4.70797 1.859 4.67307 2.04018 4.67641C2.22137 4.67976 2.40005 4.7213 2.56557 4.79853C2.7311 4.87577 2.88007 4.98714 3.00361 5.12598L6.77109 9.06972L13.0197 0.731904L13.0538 0.69045Z"
                      fill="#02DF33"
                    />
                  </svg>{" "}
                  {t("pages.title.sign_p2",{currency:(planDetails?.currency_code === "USD" ? "$" : "€")})}
                </li>
                <li>
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0538 0.69045C13.3073 0.437751 13.6446 0.298049 13.9945 0.300822C14.3444 0.303595 14.6796 0.448626 14.9294 0.705313C15.1792 0.962 15.3239 1.31026 15.3332 1.67662C15.3424 2.04297 15.2154 2.39877 14.979 2.66892L7.80023 12.0713C7.67679 12.2106 7.5278 12.3223 7.36218 12.3999C7.19656 12.4775 7.01771 12.5192 6.83632 12.5228C6.65492 12.5263 6.47471 12.4915 6.30647 12.4204C6.13822 12.3493 5.98539 12.2434 5.85711 12.109L1.09647 7.12329C0.963896 6.99391 0.857561 6.8379 0.783809 6.66455C0.710057 6.4912 0.670399 6.30406 0.667202 6.11432C0.664005 5.92457 0.697335 5.73609 0.765202 5.56012C0.833069 5.38415 0.934083 5.2243 1.06222 5.09011C1.19035 4.95591 1.34299 4.85012 1.51101 4.77905C1.67903 4.70797 1.859 4.67307 2.04018 4.67641C2.22137 4.67976 2.40005 4.7213 2.56557 4.79853C2.7311 4.87577 2.88007 4.98714 3.00361 5.12598L6.77109 9.06972L13.0197 0.731904L13.0538 0.69045Z"
                      fill="#02DF33"
                    />
                  </svg>{" "}
                  {t("pages.title.Get access to all features")}
                </li>
                <li>
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0538 0.69045C13.3073 0.437751 13.6446 0.298049 13.9945 0.300822C14.3444 0.303595 14.6796 0.448626 14.9294 0.705313C15.1792 0.962 15.3239 1.31026 15.3332 1.67662C15.3424 2.04297 15.2154 2.39877 14.979 2.66892L7.80023 12.0713C7.67679 12.2106 7.5278 12.3223 7.36218 12.3999C7.19656 12.4775 7.01771 12.5192 6.83632 12.5228C6.65492 12.5263 6.47471 12.4915 6.30647 12.4204C6.13822 12.3493 5.98539 12.2434 5.85711 12.109L1.09647 7.12329C0.963896 6.99391 0.857561 6.8379 0.783809 6.66455C0.710057 6.4912 0.670399 6.30406 0.667202 6.11432C0.664005 5.92457 0.697335 5.73609 0.765202 5.56012C0.833069 5.38415 0.934083 5.2243 1.06222 5.09011C1.19035 4.95591 1.34299 4.85012 1.51101 4.77905C1.67903 4.70797 1.859 4.67307 2.04018 4.67641C2.22137 4.67976 2.40005 4.7213 2.56557 4.79853C2.7311 4.87577 2.88007 4.98714 3.00361 5.12598L6.77109 9.06972L13.0197 0.731904L13.0538 0.69045Z"
                      fill="#02DF33"
                    />
                  </svg>{" "}
                  {t("pages.title.sign_p4",{currency:(planDetails?.currency_code === "USD" ? "$" : "€")})}
                </li>
             
                
              </ul>: 
              <ul class="pricing-features-list">
                <li>
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0538 0.69045C13.3073 0.437751 13.6446 0.298049 13.9945 0.300822C14.3444 0.303595 14.6796 0.448626 14.9294 0.705313C15.1792 0.962 15.3239 1.31026 15.3332 1.67662C15.3424 2.04297 15.2154 2.39877 14.979 2.66892L7.80023 12.0713C7.67679 12.2106 7.5278 12.3223 7.36218 12.3999C7.19656 12.4775 7.01771 12.5192 6.83632 12.5228C6.65492 12.5263 6.47471 12.4915 6.30647 12.4204C6.13822 12.3493 5.98539 12.2434 5.85711 12.109L1.09647 7.12329C0.963896 6.99391 0.857561 6.8379 0.783809 6.66455C0.710057 6.4912 0.670399 6.30406 0.667202 6.11432C0.664005 5.92457 0.697335 5.73609 0.765202 5.56012C0.833069 5.38415 0.934083 5.2243 1.06222 5.09011C1.19035 4.95591 1.34299 4.85012 1.51101 4.77905C1.67903 4.70797 1.859 4.67307 2.04018 4.67641C2.22137 4.67976 2.40005 4.7213 2.56557 4.79853C2.7311 4.87577 2.88007 4.98714 3.00361 5.12598L6.77109 9.06972L13.0197 0.731904L13.0538 0.69045Z"
                      fill="#02DF33"
                    />
                  </svg>{" "}
                  {t("pages.title.Get access to all features")}
                </li>
        
                <li>
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0538 0.69045C13.3073 0.437751 13.6446 0.298049 13.9945 0.300822C14.3444 0.303595 14.6796 0.448626 14.9294 0.705313C15.1792 0.962 15.3239 1.31026 15.3332 1.67662C15.3424 2.04297 15.2154 2.39877 14.979 2.66892L7.80023 12.0713C7.67679 12.2106 7.5278 12.3223 7.36218 12.3999C7.19656 12.4775 7.01771 12.5192 6.83632 12.5228C6.65492 12.5263 6.47471 12.4915 6.30647 12.4204C6.13822 12.3493 5.98539 12.2434 5.85711 12.109L1.09647 7.12329C0.963896 6.99391 0.857561 6.8379 0.783809 6.66455C0.710057 6.4912 0.670399 6.30406 0.667202 6.11432C0.664005 5.92457 0.697335 5.73609 0.765202 5.56012C0.833069 5.38415 0.934083 5.2243 1.06222 5.09011C1.19035 4.95591 1.34299 4.85012 1.51101 4.77905C1.67903 4.70797 1.859 4.67307 2.04018 4.67641C2.22137 4.67976 2.40005 4.7213 2.56557 4.79853C2.7311 4.87577 2.88007 4.98714 3.00361 5.12598L6.77109 9.06972L13.0197 0.731904L13.0538 0.69045Z"
                      fill="#02DF33"
                    />
                  </svg>{" "}
                  {t("pages.title.Cancel anytime within 1 click")}
                </li>
              </ul>}
              <div class="signup-left-bluewrap">
                <span class="bluewrap-top">Plan</span>
                <div class="bluewrap-mid">
                  <span>
                   {planDetails?.plan_type}
                  </span>
                  <span class="bluewrap-price">
                    {planDetails?.currency_code === "USD" ? "$" : "€"}
                    {/* {planDetails?.amount_1 === "0"
                      ? planDetails?.amount_2
                      : planDetails?.amount_1}{" "} */}
                    { `${planDetails?.amount_1} `}
                    <span>{t("pages.title.Due Today")}</span>
                  </span>
                </div>
                <span class="bluewrap-bottom">
                  {isFromOffers === 'true' ? t("pages.title.Then",{currency:(planDetails?.currency_code === "USD" ? "$" : "€")}) + " ":""}
                  {planDetails?.currency_code === "USD" ? "$" : "€"}
                  {planDetails?.amount_1 === "0"
                    ? planDetails?.amount_2
                    : planDetails?.amount_1}
                  {/* {planDetails?.amount_2 && planDetails?.amount_2 !== "0"
                    ? "/yearly"
                    : "/monthly"}{" "} */}
                  {t(`pricing.${periodSelector(planDetails?.plan_id)}`)}{" "}
                  {isFromOffers !== 'true' ? t("pages.title.after your free trial"):t("pages.title.at_renewal")}
                </span>
              </div>
              <div class="signup-left-cardwrap">
                <span class="title-22">
                  <svg
                    width="25"
                    height="29"
                    viewBox="0 0 25 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.4738 18.9852C18.627 18.9852 17.9406 18.2988 17.9406 17.452V8.52379C17.9406 5.51468 15.4924 3.06649 12.4833 3.06649C9.47416 3.06649 7.02597 5.51462 7.02597 8.52379V13.7166C7.02597 14.5634 6.33951 15.2499 5.49272 15.2499C4.64593 15.2499 3.95947 14.5634 3.95947 13.7166V8.52379C3.95947 3.82373 7.7832 0 12.4833 0C17.1833 0 21.0071 3.82373 21.0071 8.52379V17.452C21.0071 18.2988 20.3206 18.9852 19.4738 18.9852Z"
                      fill="#B1B4B5"
                    />
                    <path
                      d="M21.812 28.2701H3.15411C1.41215 28.2701 0 26.8579 0 25.116V14.3289C0 12.587 1.41215 11.1748 3.15411 11.1748H21.812C23.554 11.1748 24.9662 12.587 24.9662 14.3289V25.1159C24.9662 26.8579 23.554 28.2701 21.812 28.2701Z"
                      fill="#FFB636"
                    />
                    <path
                      d="M2.73741 25.7292H2.5794C2.08169 25.7292 1.67822 25.3257 1.67822 24.828V14.6189C1.67822 14.1212 2.08169 13.7178 2.5794 13.7178H2.73741C3.23513 13.7178 3.63859 14.1212 3.63859 14.6189V24.828C3.63859 25.3257 3.23513 25.7292 2.73741 25.7292Z"
                      fill="#FFD469"
                    />
                  </svg>{" "}
                  {t("pages.title.Payment Secured")}
                </span>
                <div class="pricing-card-img">
                  <div>
                    <img src="assets/images/visa-card.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/master-card.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/amex-card.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="signup-wraper-right">
              <div class="signup-right-shadow">
                <span class="title-22">
                  {t("pages.title.Create Your Account And")}
                  <br />
                  {t("pages.title.Get Started In Less Than 60 Seconds")}
                </span>
                <Formik
                  validateOnChange={false}
                  initialValues={initialstates}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting }) => {
                    setSubmitting(true);
                    handleSubmit(data, setSubmitting);
                  }}
                >
                  {({ isSubmitting, values, setFieldValue, errors }) => {
                    window.setFieldValue = setFieldValue;
                    return (
                      <Form
                        style={{ textAlign: "left" }}
                        noValidate
                        autoComplete="off"
                        className="signup-new-form"
                      >
                        <div class="form_group_wraper">
                          <label> {t("pages.title.Account Information")}</label>
                          <div class="nw_form_group">
                            <JumboTextField
                              fullWidth
                              name="firstname"
                              label={
                               t("pages.title.First Name")
                              }
                              value={values.firstname}
                            />
                          </div>
                          <div class="nw_form_group">
                            <JumboTextField
                              fullWidth
                              name="lastname"
                              label={
                                t("pages.title.Last Name")
                                
                              }
                              value={values.lastname}
                            />
                          </div>
                          {/* <div class="nw_form_group">
                            <JumboTextField
                              fullWidth
                              name="username"
                              type="text"
                              label={
                                t("pages.title.Username")

                              }
                              value={values.username}
                              inputProps={{
                                autocomplete: "off",
                              }}
                            />
                          </div> */}
                          <div class="nw_form_group">
                            <MuiTelInput
                              value={phone}
                              onChange={(value) => {
                                setPhone(value);
                                setFieldValue("mobile", value);
                              }}
                              defaultCountry={defaultCountry}
                              error={
                                !values.mobile ||
                                !isValidPhoneNumber(values.mobile)
                              }
                              helperText={
                                !values.mobile
                                  ? t("NewSignUpMessages.mobile.required")
                                  : !isValidPhoneNumber(values.mobile)
                                  ? t("NewSignUpMessages.mobile.invalid")
                                  : ""
                              }
                              label={
                                t("pages.title.Phone Number")
                                }
                            />
                          </div>
                          <div class="nw_form_group">
                            <JumboTextField
                              fullWidth
                              name="email"
                              type="email"
                              label={
                                t("pages.title.Email")
                              }
                            />
                          </div>
                          <div class="nw_form_group">
                            <JumboTextField
                              fullWidth
                              name="confirm_email"
                              type="email"
                              label={
                                t("pages.title.Confirm Email")
                              }
                            />
                          </div>
                          <div class="nw_form_group">
                            <JumboTextField
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              name="password"
                              label={
                                t("pages.title.Password")

                              }
                            />
                          </div>
                          <div class="nw_form_group">
                            <JumboTextField
                              type={showConfirmPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setShowConfirmPassword((show) => !show)
                                      }
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              name="confirmpassword"
                              label={
                              
                                  t("pages.title.Confirm Password")
                              }
                            />
                          </div>
                          <label>
                            {" "}
                            {t("pages.title.BILLING CONFIRMATION")}
                          </label>
                          <div class="nw_form_group">
                            <JumboTextField
                              fullWidth
                              name="company"
                              label={
                               
                                  t("pages.title.Company Name (Optional)")

                              }
                            />{" "}
                          </div>
                          <div class="nw_form_group">
                            <JumboTextField
                              label={
                                t("pages.title.Address")

                              }
                              fullWidth
                              name="address"
                              id="address"
                              ref={addressRef}
                            />{" "}
                          </div>
                          <div class="nw_form_group">
                            <JumboTextField
                              fullWidth
                              name="city"
                              label={
                                t("pages.title.City")
                              }
                            />{" "}
                          </div>
                          <div class="nw_form_group nw_form_zipcode">
                            <TextField
                              fullWidth
                              type="text"
                              name="zipCode"
                              label={
                                t("pages.title.Zip Or Postal code")

                              }
                              value={values.zipCode}
                             error={errors.zipCode}
                              helperText={errors.zipCode}
                              onChange={(e) => {
                                const value = e.target.value
                                  .replace(/[^a-zA-Z0-9]/g, "")
                                  .toUpperCase();
                                console.log(value, ":value");
                                setFieldValue("zipCode", value);
                              }}
                            />
                          </div>
                          <div class="nw_form_group">
                            <Autocomplete
                              sx={{ width: "100%" }}
                              component={TextField}
                              onChange={(event, newValue) => {
                                setFieldValue("country", newValue);
                                setSelectedCountry(newValue);
                              }}
                              id="country-select-demo"
                              options={countries}
                              filterOptions={(options, state) => {
                                const inputValue =
                                  state.inputValue.toLowerCase();
                                return options.filter((option) =>
                                  option.label
                                    .toLowerCase()
                                    .startsWith(inputValue)
                                );
                              }}
                              value={selectedCountry}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    style={{ width: 25 }}
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  {option.label} ({option.code}) +{option.phone}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <JumboTextField
                                  name="country"
                                  fullWidth
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: selectedCountry ? (
                                      <img
                                        loading="lazy"
                                        style={{
                                          width: 25,
                                          marginRight: 8,
                                          marginLeft: 10,
                                        }}
                                        src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png 2x`}
                                        alt={selectedCountry.label}
                                      />
                                    ) : null,
                                  }}
                                />
                              )}
                                
                              label={t("pages.title.Country")}
                            />
                          </div>
                          <div class="pricing-check-wraper">
                            <input
                              type="checkbox"
                              name="checkBox"
                              checked={values.checkBox}
                              onChange={() =>
                                setFieldValue("checkBox", !values.checkBox)
                              }
                            />
                            {errors.checkBox && (
                              <div style={{ color: "red" }}>Required</div>
                            )}
                            <span>
                              {/* {isFromOffers !== 'true' ? t(
                                "pages.title.I agree to a 14-day free trial starting today, followed by a subscription of"
                              ):t(
                                "pages.title.I agree to a subscription of",{currency:(planDetails?.currency_code === "USD" ? "$" : "€"),amount:(planDetails?.dueToday || 0)}
                              )}{" "} */}
                              {t("pricing.I_agree_res")}
                              {planDetails?.currency_code === "USD" ? "$" : "€"}
                              {planDetails?.amount_1 === "0"
                                ? planDetails?.amount_2
                                : planDetails?.amount_1}
                              {/* {planDetails?.amount_2 && planDetails?.amount_2 !== "0"
                                ? "/yearly"
                                : "/monthly"}{". "} */}
                              { t(`pricing.${periodSelector(planDetails?.plan_id)}`)+ ". "}
                              {t(
                                "pages.title.I understand that I can cancel through the dashboard or by email"
                              )}
                              <a href="mailto:support@novalya.com">
                                (support@novalya.com)
                              </a>
                              {t("pages.title.I have read and agree to the")}{" "}
                              <a
                                href="https://novalya.com/terms-and-conditions/"
                                target="_blank"
                              >
                                {t("pages.title.Terms and Conditions")}
                              </a>
                              .
                            </span>
                          </div>
                          <div class="pricing-btn-box">
                            <button type="submit">
                              {" "}
                              {t("pages.title.Proceed to payment")}
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <span class="pricing-right-footer">
                {t("pages.title.Already have an account?")}
                <a href="/login">Login</a>
              </span>
            </div>
          </div>
        </div>
        <div class="pricing-footer">
          <span>
            <pre>Copyright | Novalya © 2023-2024</pre>
          </span>
        </div>
      </div>
      }
     
    </>
  );
};

export default ResellerSignup;
