import React from "react";
import Crypto from "app/pages/dashboard/Crypto/Crypto";
import Page from "@jumbo/shared/Page";
import ViewReferrals from "app/pages/referrals/viewreferrals";
import Signup from "app/pages/auth-pages/signup/signup";
import UserProfile from "app/pages/users/user-profile/UserProfile";
import Logout from "app/pages/auth-pages/logout/Logout";
import Payout from "app/pages/payout/payout";
import PayoutReports from "app/pages/reports/payout/PayoutReports";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import ResetPassword from "app/pages/auth-pages/reset-password/ResetPassword";
import MessageDetails from "app/pages/messages/MessageDetails";
import Login from "app/pages/auth-pages/Login/login";
import MonthlyReport from "app/pages/reports/previousMonthRecord/MonthlyReport";
import PendingResiduelReport from "app/pages/reports/pendingResiduel/PendingResiduelReport";
import ApprovedResiduel from "app/pages/reports/approvedResiduel/ApprovedResiduel";
import PersonalReferralsReports from "app/pages/reports/personalReferrals/PersonalReferralsReports";
import SubscriptionReport from "app/pages/reports/subscriptionReport/SubscriptionReport";
import BinaryPoints from "app/pages/reports/binaryPoints/BinaryPoints";
import CheckOut from "app/pages/auth-pages/CheckOut/CheckOut";
import GetStarted from "app/pages/getStarted/GetStarted";
import Becomeaffiliate from "app/pages/getStarted/Becomeaffiliate";
import Affiliateaggrement from "app/pages/getStarted/Affiliateaggrement";
import KYC from "app/pages/getStarted/KYC";
import Updatepayoutinformation from "app/pages/getStarted/Updatepayoutinformation";
import News from "app/pages/news/News";
import SectionList from "app/pages/extension/sections/SectionList";
import CreateSection from "app/pages/extension/sections/CreateSection";
import EditSection from "app/pages/extension/sections/EditSection";
import MessageList from "app/pages/extension/messages/MessageList";
import CreateMessage from "app/pages/extension/messages/CreateMessage";
import EditMessage from "app/pages/extension/messages/EditMessage";
import Birthdaydirect from "app/pages/birthdaydirect/Birthdaydirect";
import BirthdaydirectOld from "app/pages/birthdaydirect/BirthdaydirectOld";
import Upgradepriceplan from "app/pages/upgradepriceplan/Upgradepriceplan";
import Submitpayment from "app/pages/submitpayment/Submitpayment";
import Group from "app/pages/group/group";
import Campaign from "app/pages/campaign/campaign";
import BinaryTree from "app/pages/treeview/BinaryTree";
import CreateGroup from "app/pages/extension/groups/CreateGroup";
import CreateKeywords from "app/pages/extension/keyword/CreateKeyword";

import ConnectRequest from "app/pages/extension/request/ConnectRequest";
import GroupsList from "app/pages/extension/groups/GroupsList";
import KeywordList from "app/pages/extension/keyword/KeywordList";
import UnfreindsList from "app/pages/extension/novadata/UnfreindsList";
import Lostlist from "app/pages/extension/novadata/Lostlist";
import Deactivated from "app/pages/extension/novadata/Deactivated";
import FreindsList from "app/pages/extension/novadata/FreindsList";
import Whitelist from "app/pages/extension/novadata/Whitelist";
import NovalysEvent from "app/pages/event/NovalysEvent";
import Redirect from "app/pages/redirect/Redirect";
import CrmGroup from "app/pages/new-group/Group";
import DialogForNormal from "app/pages/dialog/DialogForNormal";
import TrainingVideo from "app/pages/Video/TrainingVideo";
import EditKeywords from "app/pages/extension/keyword/EditKeyword";
import PoolReports from "app/pages/reports/poolreports/PoolReports";
import UnilevelBonusReport from "app/pages/reports/unilevel/UnilevelReport";
import PoolDistribution from "app/pages/reports/pooldistribution/PoolDistribution";
import LevelBonusDeducted from "app/pages/reports/levelbonusdeducted/LevelBonusDeducted";
import CommentAI from "app/pages/comment-ai/page";

import GrayLable from "app/pages/gray-lable/page";
import form from "app/pages/gray-lable/form";
import instagramProspection from "app/pages/instagram/instagramprospection";

import Instagram from "app/pages/instagram/page";
import NewExtensionDashboard from "app/pages/extension/extension-dashboard/NewExtensionDashboard";
import InstagramGroup from "app/pages/new-group/Instagram/InstagramGroup";
// import NewExtensionDashboard from "app/pages/extension/extension-dashboard/NewExtensionDashboard";
import AffiliateLinks from "app/pages/affiliate-links/page";
// import NewLogin from "app/pages/auth-pages/Login/NewLogin";
import { Connect } from "app/pages/extension/connect";
import ThankYouPage from "app/pages/thankyou-page";
import NewPricingPlan from "app/pages/auth-pages/PricingPlan/NewPricingPlan";
import { NewConnect } from "app/pages/extension/connect/NewConnect";
import NewSignup from "app/pages/auth-pages/signup/NewSignup";
import PricingPlan from "app/pages/auth-pages/PricingPlan";
import ResellerPlan from "app/pages/auth-pages/PricingPlan/ResellerPlan";
import NotFound from "app/NotFound";
import NewCrypto from "app/pages/dashboard/Crypto/NewCrypto";
import TermPlans from "app/pages/auth-pages/PricingPlan/TermPlans";
import AffiliateDashboard from "../pages/affiliate-dashboard/AffiliateDashboard";
import MemberDashboard from "app/pages/affiliate-dashboard/MemberDashboard";
import AllAffiliatedCustomers from "app/pages/affiliate-dashboard/AllAffiliatedCustomers";
import AffiliateLevelCommission from "app/pages/affiliate-dashboard/AffiliateLevelCommission";
import AffiliatePoolCommission from "app/pages/affiliate-dashboard/AffiliatePoolCommission";
import AffiliateMyPayments from "app/pages/affiliate-dashboard/MyPayments";
import AffiliateLink from "app/pages/affiliate-dashboard/AffiliateLink";
import AffiliateSettings from "app/pages/affiliate-dashboard/AffiliateSettings";
import { BsCalendar4Range } from "react-icons/bs";
import GermanEvents from "app/pages/german-events/page";
import Offers from "app/pages/auth-pages/PricingPlan/offers";
import UpcomingEvents from "app/pages/upcoming-events/page";
import BasicMonthly from "app/pages/basic-monthly/BasicMonthly";
import TransformerPlan from "app/pages/transformer/transformerPlan";
import ResellerPlanNew from "app/pages/auth-pages/PricingPlan/resellerPlanNew";
import ResellerSignup from "app/pages/auth-pages/signup/ResellerSignup";


/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  {
    path: "/redirect",
    element: <Page component={Redirect} />,
  },
  {
    path: "/go",
    element: <Page component={TransformerPlan} />,
  },
];

/**
 routes only accessible to authenticated users
 **/

var routesForAuthenticatedOnly = [
  {
    path: "/",
    element: <Page component={NewExtensionDashboard} />,
  },

  {
    path: "/dashboard",
    element: <Page component={Crypto} />,
  },
  // {
  //   path: "/new-dashboard",
  //   element: <Page component={NewCrypto} />,
  // },
  {
    path: "/comment-ai",
    element: <Page component={CommentAI} />,
  },
  {
    path: "/german-events",
    element: <Page component={GermanEvents} />,
  },
  {
    path: "/upcoming-events",
    element: <Page component={UpcomingEvents} />,
  },
  {
    path: "/gray-lable",
    element: <Page component={GrayLable} />,
  },

  {
    path: "/new-gray-label",
    element: <Page component={form} />,
  },

  {
    path: "/instagram-1234",
    element: <Page component={Instagram} />,
  },
  {
    path: "/affiliate-links",
    element: <Page component={AffiliateLinks} />,
  },
  {
    path: "/connects",
    element: <Page component={Connect} />,
  },
  {
    path: "/software-dashboard",
    element: <Page component={NewExtensionDashboard} />,
  },

  {
    path: "/instagram-prospections",
    element: <Page component={instagramProspection} />,
  },
  // {
  //     path: "/alertdialoglogin",
  //     element: <Page component={Alertdialoglogin} />
  // },
  {
    path: "/referrals",
    element: <Page component={ViewReferrals} />,
  },
  {
    path: "/profile",
    element: <Page component={UserProfile} />,
  },
  {
    path: "/binary-tree",
    element: <Page component={BinaryTree} />,
  },
  {
    path: "/payout-summary",
    element: <Page component={PayoutReports} />,
  },
  {
    path: "/message/:messageid",
    element: <Page component={MessageDetails} />,
  },
  {
    path: "/payout",
    element: <Page component={Payout} />,
  },
  {
    path: "/logout",
    element: <Page component={Logout} />,
  },
  {
    path: "/previous-month-record",
    element: <Page component={MonthlyReport} />,
  },
  {
    path: "/pending-residuel-report",
    element: <Page component={PendingResiduelReport} />,
  },
  {
    path: "/approved-residuel-report",
    element: <Page component={ApprovedResiduel} />,
  },
  {
    path: "/personal-referrals-report",
    element: <Page component={PersonalReferralsReports} />,
  },
  {
    path: "/subscription-report",
    element: <Page component={SubscriptionReport} />,
  },
  {
    path: "/binary-points-report",
    element: <Page component={BinaryPoints} />,
  },
  {
    path: "/get-started",
    element: <Page component={GetStarted} />,
  },
  {
    path: "/becomeaffiliate",
    element: <Page component={Becomeaffiliate} />,
  },
  {
    path: "/pool-report",
    element: <Page component={PoolReports} />,
  },
  {
    path: "/level-bonus-report",
    element: <Page component={UnilevelBonusReport} />,
  },
  {
    path: "/pool-distribution-report",
    element: <Page component={PoolDistribution} />,
  },
  {
    path: "/level-bonus-deducted",
    element: <Page component={LevelBonusDeducted} />,
  },
  {
    path: "/affiliateaggrement",
    element: <Page component={Affiliateaggrement} />,
  },
  {
    path: "/kyc",
    element: <Page component={KYC} />,
  },
  {
    path: "/updatepayoutinformation",
    element: <Page component={Updatepayoutinformation} />,
  },
  {
    path: "/prospection/facebook",
    element: <Page key="Facebook" component={Connect} />,
  },
  {
    path: "/prospection/instagram",
    element: <Page key="Instagram" component={Connect} />,
  },
  {
    path: "/prospection/new-instagram",
    element: <Page key="Instagram" component={NewConnect} />,
  },
  {
    path: "/group",
    element: <Page component={Group} />,
  },
  {
    path: "/connect-group",
    element: <Page component={GroupsList} />,
  },
  {
    path: "/keywords",
    element: <Page component={KeywordList} />,
  },
  {
    path: "/create-keyword",
    element: <Page component={CreateKeywords} />,
  },
  {
    path: "/prospection/connect-request",
    element: <Page component={ConnectRequest} />,
  },
  {
    path: "/create/groups",
    element: <Page component={CreateGroup} />,
  },
  {
    path: "/create/keywords",
    element: <Page component={CreateKeywords} />,
  },
  {
    path: "/edit/keywords/:keywordId",
    element: <Page component={EditKeywords} />,
  },
  {
    path: "/campaign",
    element: <Page component={Campaign} />,
  },
  {
    path: "/sections",
    element: <Page component={SectionList} />,
  },
  {
    path: "/create/sections",
    element: <Page component={CreateSection} />,
  },
  {
    path: "/edit/sections/:sectionId",
    element: <Page component={EditSection} />,
  },
  {
    path: "/messages",
    element: <Page component={MessageList} />,
  },
  {
    path: "/create/message",
    element: <Page component={CreateMessage} />,
  },
  {
    path: "/edit/message/:messageId",
    element: <Page component={EditMessage} />,
  },
  {
    path: "/prospection/birthday",
    element: <Page component={Birthdaydirect} />,
  },
  {
    path: "/prospection/birthdayOld",
    element: <Page component={BirthdaydirectOld} />,
  },
  {
    path: "/upgrade-price-plan",
    element: <Page component={Upgradepriceplan} />,
  },
  {
    path: "/submit-payment",
    element: <Page component={Submitpayment} />,
  },
  {
    path: "/event",
    element: <Page component={NovalysEvent} />,
  },
  {
    path: "/news",
    element: <Page component={News} />,
  },
  {
    path: "/new-group",
    element: <Page component={CrmGroup} />,
  },
  {
    path: "/instagram-crm",
    element: <Page component={InstagramGroup} />,
  },
  {
    path: "/friends",
    element: <Page component={FreindsList} />,
  },
  {
    path: "/unfriended",
    element: <Page component={UnfreindsList} />,
  },
  {
    path: "/lost",
    element: <Page component={Lostlist} />,
  },
  {
    path: "/deactivated",
    element: <Page component={Deactivated} />,
  },
  {
    path: "/whitelist",
    element: <Page component={Whitelist} />,
  },
  {
    path: "/normal-users",
    element: <Page component={DialogForNormal} />,
  },
  {
    path: "/training-videos",
    element: <Page component={TrainingVideo} />,
  },
  {
    path: "/error",
    element: <Page component={NotFound} />,
  },
  {
    path: "/affiliate",
    element: <Page component={AffiliateDashboard} />,
  },
  // {
  //   path: "/affiliate/2",
  //   element: <Page component={MemberDashboard} />,
  // },
  {
    path: "/affiliate/links",
    element: <Page component={AffiliateLink} />,
  },
  {
    path: "/affiliate/all-customers",
    element: <Page component={AllAffiliatedCustomers} />,
  },
  {
    path: "/affiliate/level-commission",
    element: <Page component={AffiliateLevelCommission} />,
  },
  // {
  //   path: "/affiliate/pool-commission",
  //   element: <Page component={AffiliatePoolCommission} />,
  // },
  {
    path: "/affiliate/payments",
    element: <Page component={AffiliateMyPayments} />,
  },
  {
    path: "/affiliate/settings",
    element: <Page component={AffiliateSettings} />,
  },
  
];



const getPlansComponent = () => {
  const hostname = window.location.hostname;

  if (hostname.includes("app.novalya.com") || hostname === "localhost") {
    return TermPlans;
  } else {
    return ResellerPlanNew;
  }
};

const getSignUpComponent = () => {
  const hostname = window.location.hostname;

  if (hostname.includes("app.novalya.com") || hostname === "localhost") {
    return NewSignup;
  } else {
    return ResellerSignup;
  }
};
/**
 routes only accessible when user is anonymous
 **/ //
const routesForNotAuthenticatedOnly = [
  {
    path: "/login",
    element: <Page component={Login} />,
  },
  {
    path: "/registration-complete",
    element: <Page component={ThankYouPage} />,
  },
  // {
  //   path: "/new-login",
  //   element: <Page component={NewLogin} />,
  // },
  {
    path: "/login/:token/:email",
    element: <Page component={Login} />,
  },
  {
    path: "/signup/:referralid",
    element: <Page component={NewSignup} />,
  },
  {
    path: "/signup",
    element: <Page component={getSignUpComponent()} />,
  },
  {
    path: "/forget-password",
    element: <Page component={ForgotPassword} />,
  },
  {
    path: "/reset-password/:token/:email",
    element: <Page component={ResetPassword} />,
  },

  {
    path: "/new-plans",
    element: <Page component={TermPlans} />,
  },
  {
    path: "/plans",
    element: <Page component={getPlansComponent()} />,
  },
  {
    path: "/offers",
    element: <Page component={Offers} />,
  },
  {
    path: "/check-out",
    element: <Page component={CheckOut} />,
  },
  {
    path: "/basic-monthly",
    element: <Page component={BasicMonthly} />,
  },
 
];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
