import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItemIcon,
  Menu,
  IconButton,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import {
  API_BASE_URL,
  createInstagramCrmGroup,
  fetchInstagramCrmGroups,
  fetchInstagramCrmSingleGroups,
  fetchMessageList,
  fetchPlanLimitDetails,
  getAllMessagesList,
  getStatisticLimitData,
} from "backendServices/ApiCalls";
import { useEffect } from "react";
import { useMemo } from "react";
import { useModalState } from "app/hooks/use-modal-state";
import { useTranslation } from "react-i18next";
import { RgbaColorPicker } from "react-colorful";
import SweetAlert from "../../components/mui/Alerts/SweetAlert";
import axios from "axios";
import GroupUser from "./InstagramGroupUser";
import Campaign from "../../campaign/campaign";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { InstagramHiddenScrool } from "./InstagramHiddenScrool";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { InstagramExceedLimitModal } from "./InstagramExceedLimitDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "../assets/css/style.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TopHeader from "../../components/mui/TopHeader/TopHeader";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function InstagramGroup() {
  const [savedGroups, setSavedGroups] = useState([]);
  const [groupUsers, setGroupUsers] = useState();
  const [editingGroupId, setEditingGroupId] = useState(null); // Added
  const [searchValue, setSearchValue] = useState("");
  const [groupName, setGroupName] = useState("");
  const [editGroupName, setEditGroupName] = useState("");
  const [deleteGroupId, setDeleteGroupId] = useState(null);
  const [prevGroupId, setPrevGroupId] = useState(null); // Added
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const createGroup = useModalState();
  const editGroup = useModalState();
  const showColorPicker = useModalState();
  const deleteGroup = useModalState();
  const showCampaign = useModalState();
  const sendCampaignModal = useModalState();
  const exceedLimitModal = useModalState();
  const { t } = useTranslation();
  const [planLimit, setPlanLimit] = useState();
  const [color, setColor] = useState({ r: 255, g: 255, b: 255, a: 1 });
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const [showAction, setShowAction] = useState(false);

  const [messageData, setMessageData] = useState([]);
  const [campiagnData, setCampiagnData] = useState({});
  const [campiagnModalData, setCampiagnModalData] = useState({
    userIds: null,
    peopleCount: null,
    time_interval: "6-10 Min",
    message_id: null,
    selectAction: null,
    moveStageId: null,
    moveGroupId: null,
  });
  const [showCampign, setShowCampign] = useState(false);
  const theme = useTheme();
  const [filteredUsersByStage, setFilteredUsersByStage] = useState([]);
  const [stageName, setStageName] = useState(null);
  const [showLanguageChangeModal, setShowLanguageChangeModal] = useState(false);
  const [showMenu, setShowMenu] = useState({
    isOpen: false,
    anchorEl: null,
    group: null,
  });
  const [limits, setLimits] = useState({});

  const [groups, setGroups] = useState([]);
  const [editColor, setEditColor] = useState();

  const handleMenuOpen = (group) => (e) => {
    setShowMenu({
      isOpen: true,
      anchorEl: e.currentTarget,
      group: group,
    });
  };

  const fetchPlanLimitDetailsFunction = async () => {
    let params = { type: "instagram" };
    fetchPlanLimitDetails(
      params,
      (response) => {
        if (response?.data?.data) setPlanLimit(response?.data?.data);
      },
      (error) => {}
    );
  };

  const fetchStatisticLimitData = async () => {
    let params = {};
    getStatisticLimitData(
      params,
      (response) => {
        if (response?.data?.response) {
          setLimits(response?.data?.response);
        }
      },
      (error) => {}
    );
  };

  const handleMenuClose = () => {
    setShowMenu({
      isOpen: false,
      anchorEl: null,
      group: null,
    });
  };
  const fetchGroupdData = async () => {
    let params = {};

    fetchInstagramCrmGroups(
      params,
      (response) => {
        if (response?.data != undefined) {
          const groupsWithColor = response.data.map((group) => ({
            ...group,
            custom_color: group.custom_color || {
              r: 255,
              g: 255,
              b: 255,
              a: 1,
            },
          }));
          setSavedGroups(groupsWithColor);
        }
      },
      (error) => {}
    );
  };

  const handleGroupUsers = (groupId) => {
    setPrevGroupId(groupId);
    if (prevGroupId === groupId) {
      showCampaign.toggle();
      return;
    }
    fetchInstagramCrmSingleGroups(
      { groupId },
      (response) => {
        if (response?.data != undefined) {
          setGroupUsers(response.data);
          showCampaign.onClose();
          showCampaign.onOpen();
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    getAllMessagesList(
      JSON.stringify(['ig_crm']),
      (response) => {
        if (response?.data) setMessageData(response?.data);
      },
      (error) => {}
    );
    fetchGroupdData();
    fetchPlanLimitDetailsFunction();
    fetchStatisticLimitData();
  }, []);

  const handleEditGroup = () => {
    const group = showMenu.group;
    setEditingGroupId(group.id);
    setEditGroupName(group.name);
    setColor(group.custom_color);
    editGroup.onOpen();
    setEditColor(group.custom_color);
  };

  const formatColorString = (colorObj) => {
    if (typeof colorObj === "string") {
      return colorObj;
    } else if (
      typeof colorObj === "object" &&
      colorObj !== null &&
      "r" in colorObj &&
      "g" in colorObj &&
      "b" in colorObj &&
      "a" in colorObj
    ) {
      return `rgba(${colorObj.r}, ${colorObj.g}, ${colorObj.b}, ${colorObj.a})`;
    } else {
      return `rgba(255, 255, 255, 1)`;
    }
  };

  const handleColorChange = (newColor) => {
    if(newColor.r && newColor.g && newColor.b){
      const updatedColor = {
        r: newColor.r,
        g: newColor.g,
        b: newColor.b,
        a: 1,
      };
      setColor(updatedColor);
    }
  };
  const filteredGroupData = useMemo(() => {
    const lowerSearchValue = searchValue.toLowerCase();
    return savedGroups.filter((group) => {
      return group.name.toLowerCase().includes(lowerSearchValue);
    });
  }, [savedGroups, searchValue]);

  useEffect(() => {
    setGroups(filteredGroupData);
  }, [filteredGroupData]);

  function handleInstagramGroupSave() {
    if (loading) {
      return true;
    }
    if (!groupName.trim()) {
      setalertData({
        show: true,
        message: "Group name cannot be empty",
        variant: "error",
      });
      return;
    }
    const groupsName = savedGroups.map((group) => group.name.trim());
    if (groupsName.includes(groupName.trim())) {
      setalertData({
        show: true,
        message: `${t("pages.title.Group Name Already Exists")}`,
        variant: "error",
      });
      return;
    }

    let no_stages_group = false;
   
    setLoading(true);
    createInstagramCrmGroup(
      {
        name: groupName.trim(),
        custom_color: formatColorString(color),
        no_stages_group: no_stages_group,
      },
      (response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Created Successfully")}`,
          variant: "success",
        });
        fetchGroupdData();
        fetchPlanLimitDetailsFunction();
        setGroupName("");
        setColor("");
        createGroup.onClose();
        showColorPicker.toggle();
        setLoading(false);
      },
      (error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
        createGroup.onClose();
        setLoading(false);
      }
    );
  }

  function handleInstagramGroupUpdate() {
    const groupsName = savedGroups.map((group) => group.name.trim());
    const index = savedGroups.findIndex(
      (item) => item.name.trim() === editGroupName.trim()
    );
    if (index > -1) {
      const matchedId = savedGroups[index].id;
      if (
        groupsName.includes(editGroupName.trim()) &&
        editingGroupId !== matchedId
      ) {
        setalertData({
          show: true,
          message: `${t("pages.title.Group Name Already Exists")}`,
          variant: "error",
        });
        return;
      }
    }
    if (!editGroupName.trim()) {
      setalertData({
        show: true,
        message: "Group name cannot be empty",
        variant: "error",
      });
      return;
    }
    axios
      .patch(`${API_BASE_URL}/instagram/group/${editingGroupId}`, {
        name: editGroupName,
        custom_color: formatColorString(color),
      })
      .then((response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Updated Successfully")}`,
          variant: "success",
        });
        fetchGroupdData();
        editGroup.onClose();
        showColorPicker.toggle();
        handleMenuClose();
      })
      .catch((error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
        editGroup.onClose();
      });
  }

  async function handleDeleteInstagramGroup() {
    await axios.delete(`${API_BASE_URL}/instagram/group/${deleteGroupId}`);
    fetchGroupdData();
    fetchPlanLimitDetailsFunction();
    deleteGroup.onClose();
    setalertData({
      show: true,
      message: `${t("pages.title.Deleted Successfully")}`,
      variant: "success",
    });
    setGroupUsers(null);
    handleMenuClose();
  }

 

  const handleCampiagn = () => {
    const group = showMenu.group;
    setCampiagnData(group);
    // setCampiagnModalData({
    fetchInstagramCrmSingleGroups(
      { groupId: group.id },
      (response) => {
        if (response?.data !== undefined) {
          const userIds = response.data.taggedUsers.map((user) => user.id);
          const peopleCount = userIds.length;
          setCampiagnModalData({
            userIds: userIds,
            peopleCount: peopleCount,
            time_interval: "6-10 Min",
            message_id: null,
          });
          sendCampaignModal.onOpen();
        }
      },
      (error) => {}
    );

    // setShowCampign(true);
  };
  function toggleDrawer() {
    setOpen((prev) => !prev);
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedGroups = [...groups];
    const [reorderedGroup] = updatedGroups.splice(result.source.index, 1);
    updatedGroups.splice(result.destination.index, 0, reorderedGroup);
    axios.post(`${API_BASE_URL}/instagram/reorderGroup`, {
      source: result.source.index,
      destination: result.destination.index,
    });
    setGroups(updatedGroups);
  };

  useEffect(() => {
    window.addEventListener(
      "nonEnglishLanguageDetected_Instagram_CRM",
      (event) => {
        setShowLanguageChangeModal(true);
      }
    );

    return () => {
      window.removeEventListener(
        "nonEnglishLanguageDetected_Instagram_CRM",
        () => {}
      );
    };
  }, []);

  return (
    <>
      {!showCampign && (
        <>
          {alertData.show ? (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          ) : (
            ""
          )}
          <TopHeader
            title="Instagram CRM"
            url={t("pages.title.crm_video_url")}
          />
          <div className="crm-right-wrap">
            <div className="container">
              {showLanguageChangeModal && (
                <Dialog
                  open={showLanguageChangeModal}
                  onClose={() => {
                    setShowLanguageChangeModal(false);
                  }}
                  maxWidth="lg"
                  fullWidth
                  PaperProps={{ style: { borderRadius: "10px" } }}
                >
                  <DialogTitle
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'Inter'",
                        fontSize: "22px",
                        fontWeight: "600",
                        lineHeight: "28px",
                        textAlign: "left",
                        color: "rgba(99, 99, 99, 1)",
                      }}
                    >
                      {t("languagePopUpTitle")}
                    </span>
                    <span>
                      <img
                        src="/images/instagram-eng.svg"
                        alt=""
                        style={{ height: "40px", width: "auto" }}
                      />
                    </span>
                  </DialogTitle>
                  <DialogContent
                    onInteractOutside={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div
                      style={{ padding: "56.25% 0 0 0", position: "relative" }}
                    >
                      <iframe
                        src={
                          t(`languagePopUpVideoURL.Instagram`) +
                          "&autoplay=1&muted=1"
                        }
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          boxShadow: "0px 0px 7px 2px #b5b5b5",
                        }}
                        title="how to"
                      ></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: "center" }}>
                    <button
                      style={{
                        width: "180px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                      }}
                      className="bordered-btn bordered-btn-bg"
                      onClick={() => {
                        setShowLanguageChangeModal(false);
                      }}
                      id="instagram_crm-2-connect"
                    >
                      <span>{t("languagePopUpRestartButton")}</span>
                      <span>
                        <RestartAltIcon
                          style={{ height: "20px", width: "20px" }}
                        />
                      </span>
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              <div
                className={
                  open ? "crm-right-groupnova" : "crm-right-groupnova-toggle "
                }
              >
                <div className="crm-right-main-groups">
                  <div className="crm-right-gruop">
                    <IconButton
                      onClick={toggleDrawer}
                      sx={{
                        // position: "absolute",
                        // right: -8,
                        // top: 8,
                        height: 24,
                        width: 24,
                        backgroundColor: "#2C73FF",
                        color: "#ffffff",
                        border: "1px solid rgba(145, 158, 171, 0.32)",
                        padding: 0,
                        zIndex: (theme) => theme.zIndex.drawer + 2,
                        "&:hover": {
                          backgroundColor: "#2C73FF",
                        },
                      }}
                    >
                      <span>
                        <ChevronLeftIcon
                          sx={{
                            height: "20px",
                            color: "#ffffff",
                            transform: `rotate(${open ? 0 : 180}deg)`,
                          }}
                        />
                      </span>
                    </IconButton>
                    <div className="crm-right-hd">
                      {open ? (
                        <>
                          <h2 className="crm-hd">
                            {t("pages.title.crm_group")}
                          </h2>
                          <button
                            className="crm-top-add-btn"
                            onClick={() => {
                              if (
                                planLimit?.tagCount >=
                                planLimit?.userPlanLimit?.tags_pipelines
                              ) {
                                setalertData({
                                  show: true,
                                  message:
                                    "You have reached the limit of groups",
                                  variant: "error",
                                });
                                return;
                              }
                              createGroup.onOpen();
                            }}
                          >
                            <span>
                              {" "}
                              <svg
                                width="15"
                                height="16"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.21098 8.51251L8.21098 11.0624C8.21116 11.3453 7.9814 11.5751 7.69849 11.5749C7.41548 11.5748 7.1861 11.3455 7.1861 11.0624L7.1861 8.51252L4.63619 8.51252L4.63614 8.51252C4.35304 8.51234 4.12363 8.28302 4.1236 7.99998L8.21098 8.51251ZM8.21098 8.51251L10.7609 8.51251C11.0442 8.51251 11.2737 8.28314 11.2733 7.99998C11.2733 7.717 11.0439 7.48765 10.7609 7.48765L8.21098 7.48765V4.93773C8.21098 4.65468 7.98156 4.42533 7.69844 4.42515H7.69839C7.41536 4.42515 7.18596 4.65455 7.18596 4.93758L7.18596 7.48749L4.63609 7.48749C4.3532 7.48731 4.12345 7.71704 4.1236 7.99994L8.21098 8.51251ZM12.7013 2.9973C9.93839 0.234358 5.45871 0.234358 2.69577 2.9973C-0.0673305 5.7604 -0.0671737 10.2399 2.69577 13.0029C5.45886 15.766 9.93823 15.766 12.7013 13.0029C15.4643 10.2399 15.4644 5.7604 12.7013 2.9973ZM3.41081 12.2878C1.05215 9.92917 1.06159 6.08105 3.42057 3.72207C5.77938 1.36326 9.61775 1.36326 11.9766 3.72207C14.3354 6.08087 14.3354 9.91926 11.9766 12.2781C9.61761 14.637 5.76946 14.6465 3.41081 12.2878Z"
                                  fill="white"
                                  stroke="white"
                                  strokeWidth="0.15"
                                />
                              </svg>
                            </span>
                            <span>{t("pages.title.crm_add_group")}</span>
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <LightTooltip title={t("pages.title.crm_add_group")}>
                            <IconButton
                              color="secondary"
                              sx={{
                                border: "1px solid #ccc",
                                mb: 1,
                                color: "#2C73FF",
                                margin: "5px auto",
                              }}
                              size="large"
                              onClick={createGroup.onOpen}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </LightTooltip>
                        </>
                      )}
                    </div>
                  </div>

                  {/* start add group popup */}
                  <Dialog
                    open={createGroup.isOpen}
                    onClose={createGroup.onClose}
                    sx={{
                      ".MuiDialog-container": {
                        mt: "-80px",
                      },
                      "& .MuiDialog-paper": {
                        width: "80%",
                        marginTop: "174px",
                        borderRadius: "12px",
                      },
                    }}
                  >
                    <Box></Box>
                    <DialogTitle
                      sx={{
                        backgroundColor: "#F4F1FA",
                        textAlign: "center",
                        fontFamily: "DM Sans",
                        fontWeight: "500",
                        fontSize: "25px",
                      }}
                    >
                      {t("pages.title.crm_add_group_title")}
                    </DialogTitle>
                    <DialogContent sx={{ pt: "8px !important" }}>
                      <DialogContentText></DialogContentText>
                      <div>{t("pages.title.add_group")}</div>
                      <TextField
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        fullWidth
                        placeholder={t("pages.title.placeholder_group_name")}
                        sx={{
                          backgroundColor: "#F6F6F6",
                          mt: "10px",
                          mb: "20px",
                        }}
                      />
                      <div>Select the color</div>
                      <ListItemIcon
                        onClick={showColorPicker.toggle}
                        sx={{ cursor: "pointer", mt: 1 }}
                      >
                        <Box
                          sx={{
                            border: "1px solid #DED6F0",
                            padding: ".2rem",
                            borderRadius: "4px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              height: "1.75rem",
                              border: "1px solid #DED6F0",
                              borderRadius: "4px",
                              backgroundColor: formatColorString(color),
                            }}
                          />
                        </Box>
                      </ListItemIcon>
                      <Box>
                        {/* {showColorPicker.isOpen && ( */}
                        <RgbaColorPicker
                          color={color}
                          className="hideAlphaControl"
                          onChange={handleColorChange}
                        />
                        {/* )} */}
                      </Box>
                    </DialogContent>
                    <DialogActions sx={{ backgroundColor: "#F4F1FA" }}>
                      <button
                        onClick={handleInstagramGroupSave}
                        className="green-pop-btn"
                      >
                        <span>{t("pages.title.crm_add")}</span>
                      </button>
                      <button
                        onClick={() => {
                          createGroup.onClose();
                          showColorPicker.toggle();
                        }}
                        className="gray-pop-btn"
                      >
                        <span>{t("pages.title.crm_cancel")}</span>
                      </button>
                    </DialogActions>
                  </Dialog>
                  {/* end add group popup */}
                  {open ? (
                    <div className="crm-right-search">
                      <form
                        className="serach-form"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <input
                          type="text"
                          name=""
                          placeholder="Search ...."
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <p
                          className="search-btn"
                          onClick={(e) => setSearchValue(searchValue)}
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_2083_436)">
                              <path
                                d="M10.0833 1.83301C14.6373 1.83301 18.3333 5.52901 18.3333 10.083C18.3333 14.637 14.6373 18.333 10.0833 18.333C5.52933 18.333 1.83333 14.637 1.83333 10.083C1.83333 5.52901 5.52933 1.83301 10.0833 1.83301ZM10.0833 16.4997C13.6281 16.4997 16.5 13.6278 16.5 10.083C16.5 6.53734 13.6281 3.66634 10.0833 3.66634C6.53766 3.66634 3.66666 6.53734 3.66666 10.083C3.66666 13.6278 6.53766 16.4997 10.0833 16.4997ZM17.8612 16.5648L20.4545 19.1571L19.1574 20.4542L16.5651 17.8609L17.8612 16.5648Z"
                                fill="#7551D2"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_2083_436">
                                <rect
                                  width="22"
                                  height="22"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </p>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="crm-right-group-listing">
                    <ul className="crm-listing-main">
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="groups">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {groups.map((group, index) => (
                                <Draggable
                                  key={group.id.toString()}
                                  draggableId={group.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <InstagramHiddenScrool
                                        key={index}
                                        data-id={index}
                                        sx={{
                                          padding: 1,
                                          mb: 1,
                                          mr: open ? 0 : -1,
                                          overflowX: "auto",
                                          width: open ? "auto" : "49px",
                                          borderBottom: "1px solid white",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                            onClick={() =>
                                              handleGroupUsers(group.id)
                                            }
                                          >
                                            <Avatar
                                              sx={{
                                                cursor: "pointer",
                                                backgroundColor:
                                                  group.custom_color,
                                                width: 40,
                                                height: 40,
                                                mr: 1,
                                                borderRadius: "11px",
                                              }}
                                            >
                                              <span>
                                                {group.name
                                                  .charAt(0)
                                                  .toUpperCase()}
                                              </span>
                                              <span>
                                                {" "}
                                                {group.name
                                                  .charAt(1)
                                                  .toUpperCase()}
                                              </span>
                                            </Avatar>
                                            <LightTooltip title={group.name}>
                                              <Typography
                                                sx={{
                                                  maxWidth: 150,
                                                  color: " #2F2F2F",
                                                  fontSize: " 14px",
                                                  fontWeight: "500",
                                                }}
                                                noWrap
                                                variant="h3"
                                              >
                                                {group.name}
                                              </Typography>
                                            </LightTooltip>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: ".5rem",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <MoreVertIcon
                                              onClick={handleMenuOpen(group)}
                                            />
                                            <Menu
                                              // id="menu-appbar"
                                              anchorEl={showMenu.anchorEl}
                                              anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              sx={{
                                                "& .MuiPaper-root": {
                                                  boxShadow: "none",
                                                },
                                              }}
                                              keepMounted
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              open={showMenu.isOpen}
                                              onClose={handleMenuClose}
                                            >
                                              {/* <MenuItem
                                                onClick={handleCampiagn}
                                                sx={{
                                                  m: "10px",
                                                  mt: "-1px",
                                                  borderRadius: "5px",
                                                  "&:hover": {
                                                    backgroundColor: "#F4F1FA",
                                                    color: "#2C73FF",
                                                  },
                                                }}
                                              >
                                                <CampaignIcon
                                                  sx={{
                                                    fontSize: "lg",
                                                    marginRight: "4px",
                                                    ml: "-5px",
                                                  }}
                                                />
                                                {t("pages.title.crm_campaign")}
                                              </MenuItem> */}
                                              <MenuItem
                                                onClick={() => {
                                                  handleEditGroup();
                                                  handleMenuClose();
                                                }}
                                                sx={{
                                                  m: "10px",
                                                  mt: "-1px",
                                                  borderRadius: "5px",
                                                  "&:hover": {
                                                    backgroundColor: "#F4F1FA",
                                                    color: "#2C73FF",
                                                  },
                                                }}
                                              >
                                                <span>
                                                  <svg
                                                    width="13"
                                                    height="10"
                                                    viewBox="0 0 13 10"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{
                                                      fontSize: "lg",
                                                      marginRight: "4px",
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    <path
                                                      d="M10.647 5.48889L6.86098 8.85556V10H8.14586L11.9256 6.62778M12.9111 5.32222L12.1128 4.61111C12.0827 4.5816 12.0462 4.55796 12.0054 4.5417C11.9646 4.52544 11.9204 4.51692 11.8757 4.51667C11.7822 4.52222 11.6949 4.55 11.6325 4.61111L11.0088 5.16667L12.2874 6.27778L12.9111 5.72222C13.0296 5.61111 13.0296 5.43333 12.9111 5.32222ZM5.61353 7.77778H1.24745V2.22222L6.23726 5L11.2271 2.22222V3.33333H12.4745V1.11111C12.4745 0.5 11.9132 0 11.2271 0H1.24745C0.561353 0 0 0.5 0 1.11111V7.77778C0 8.38889 0.561353 8.88889 1.24745 8.88889H5.61353V7.77778ZM11.2271 1.11111L6.23726 3.88889L1.24745 1.11111H11.2271Z"
                                                      fill="#8C8C8C"
                                                    />
                                                  </svg>
                                                </span>
                                                <span>
                                                  {t("pages.title.crm_edit")}
                                                </span>
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() => {
                                                  const group = showMenu.group;
                                                  setDeleteGroupId(group.id);
                                                  deleteGroup.onOpen();
                                                  handleMenuClose();
                                                }}
                                                sx={{
                                                  m: "10px",
                                                  mt: "-1px",
                                                  borderRadius: "5px",
                                                  "&:hover": {
                                                    backgroundColor: "#F4F1FA",
                                                    color: "#2C73FF",
                                                  },
                                                }}
                                              >
                                                <span>
                                                  <svg
                                                    width="10"
                                                    height="12"
                                                    viewBox="0 0 10 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{
                                                      fontSize: "lg",
                                                      marginRight: "4px",
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    <path
                                                      d="M1.875 12C1.53125 12 1.23708 11.8696 0.9925 11.6087C0.747917 11.3478 0.625417 11.0338 0.625 10.6667V2H0V0.666667H3.125V0H6.875V0.666667H10V2H9.375V10.6667C9.375 11.0333 9.25271 11.3473 9.00813 11.6087C8.76354 11.87 8.46917 12.0004 8.125 12H1.875ZM8.125 2H1.875V10.6667H8.125V2ZM3.125 9.33333H4.375V3.33333H3.125V9.33333ZM5.625 9.33333H6.875V3.33333H5.625V9.33333Z"
                                                      fill="#8C8C8C"
                                                    />
                                                  </svg>
                                                </span>
                                                <span>
                                                  {t("pages.title.crm_delete")}
                                                </span>
                                              </MenuItem>
                                            </Menu>
                                          </Box>
                                        </Box>
                                      </InstagramHiddenScrool>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      {/* delete confirmation start here  */}
                      <Dialog
                        maxWidth="xs"
                        open={deleteGroup.isOpen}
                        sx={{
                          ".MuiDialog-container": {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                          "& .MuiDialog-paper": {
                            borderRadius: 5,
                          },
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#F4F1FA",
                            color: "#2C73FF",
                            p: 2,
                          }}
                        >
                          {" "}
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "20px",
                              textAlign: "center",
                              flexGrow: 1,
                              color: "#2C73FF",
                            }}
                          >
                            Delete Group
                          </Typography>
                        </Box>
                        <DialogContent
                          className="stage-popup-main"
                          sx={{ padding: "0px" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                              mt: 1,
                            }}
                          ></Box>
                          <Typography
                            color="grey.600"
                            variant="body2"
                            sx={{
                              width: "400px",
                              marginBottom: "36px",
                              minHeight: "70px",
                            }}
                          >
                            {t("pages.title.delete_data")}
                          </Typography>
                          <Box
                            className="stage-btn-group"
                            sx={{
                              backgroundColor: "#F4F1FA",
                              m: "-20px",
                              pb: "15px",
                              pt: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: 2,
                              }}
                            >
                              <button
                                className="red-pop-btn"
                                onClick={handleDeleteInstagramGroup}
                              >
                                <span>{t("pages.title.delete")}</span>
                              </button>
                              <button
                                className="gray-pop-btn"
                                onClick={deleteGroup.onClose}
                              >
                                <span>{t("pages.title.cancel")}</span>
                              </button>
                            </Box>
                          </Box>
                        </DialogContent>
                      </Dialog>
                      {/* delete confirmation end here  */}

                      {/* edit group start here  */}
                      <Dialog
                        open={editGroup.isOpen}
                        onClose={editGroup.onClose}
                        sx={{
                          ".MuiDialog-container": {
                            mt: "-80px",
                          },
                          "& .MuiDialog-paper": {
                            width: "80%",
                            marginTop: "174px",
                            borderRadius: "12px",
                          },
                        }}
                      >
                        <DialogTitle
                          sx={{
                            backgroundColor: "#F4F1FA",
                            textAlign: "center",
                            fontFamily: "DM Sans",
                            fontWeight: "500",
                            fontSize: "25px",
                          }}
                        >
                          {t("pages.title.crm_edit_group_title")}
                        </DialogTitle>
                        <DialogContent sx={{ pt: "8px !important" }}>
                          <div>{t("pages.title.add_group")}</div>
                          <TextField
                            value={editGroupName}
                            onChange={(e) => setEditGroupName(e.target.value)}
                            fullWidth
                            placeholder={t(
                              "pages.title.placeholder_group_name"
                            )}
                            sx={{
                              backgroundColor: "#F6F6F6",
                              mt: "10px",
                              mb: "20px",
                            }}
                          />{" "}
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <div>Select the color</div>
                              <ListItemIcon
                                onClick={showColorPicker.toggle}
                                sx={{ cursor: "pointer", mt: 2 }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #DED6F0",
                                    padding: ".2rem",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "1.75rem",
                                      border: "1px solid #DED6F0",
                                      borderRadius: "4px",
                                      backgroundColor: formatColorString(color),
                                    }}
                                  />
                                </Box>
                              </ListItemIcon>
                            </Box>
                            <Box>
                              <div>Previous color</div>
                              <ListItemIcon sx={{ cursor: "pointer", mt: 1 }}>
                                <Box
                                  sx={{
                                    border: "1px solid #DED6F0",
                                    padding: ".2rem",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "1.75rem",
                                      border: "1px solid #DED6F0",
                                      borderRadius: "4px",
                                      backgroundColor:
                                        formatColorString(editColor),
                                    }}
                                  />
                                </Box>
                              </ListItemIcon>
                            </Box>
                          </Box>
                          <Box>
                            {/* {showColorPicker.isOpen && ( */}
                            <RgbaColorPicker
                              color={color}
                              className="hideAlphaControl"
                              onChange={handleColorChange}
                            />
                            {/* )} */}
                          </Box>
                        </DialogContent>
                        <DialogActions sx={{ backgroundColor: "#F4F1FA" }}>
                          <button
                            onClick={handleInstagramGroupUpdate}
                            className="green-pop-btn"
                          >
                            <span>{t("pages.title.update")}</span>
                          </button>
                          <button
                            onClick={() => {
                              editGroup.onClose();
                              showColorPicker.toggle();
                            }}
                            className="gray-pop-btn"
                          >
                            <span>{t("pages.title.crm_cancel")}</span>
                          </button>
                        </DialogActions>
                      </Dialog>
                      {/* edit group end here  */}
                    </ul>
                  </div>
                </div>
                <Grid
                  className="crm-right-list-inner"
                  item
                  xs={9}
                  sx={{ overflow: "hidden" }}
                >
                  {showCampaign.isOpen ? (
                    <GroupUser
                      groupUsers={groupUsers}
                      setFilteredUsersByStage={setFilteredUsersByStage}
                      setShowCampign={setShowCampign}
                      setCampiagnData={setCampiagnData}
                      setStageName={setStageName}
                      setCampiagnModalData={setCampiagnModalData}
                      sendCampaignModal={sendCampaignModal}
                      planLimit={planLimit}
                      groupPop={() => deleteGroup.onOpen()}
                      setDeleteGroupId={setDeleteGroupId}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Dialog
                  maxWidth="md"
                  open={sendCampaignModal.isOpen}
                  sx={{
                    ".MuiDialog-container": {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    "& .MuiDialog-paper": {
                      borderRadius: 4,
                    },
                  }}
                >
                  <DialogContent
                    className="stage-popup-main"
                    sx={{ padding: "0px" }}
                  >
                    <Box
                      className="campaign-title"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#F4F1FA",
                      }}
                    >
                      <Typography
                        mt={1}
                        textAlign="center"
                        component="h5"
                        variant="h5"
                        sx={{
                          fontSize: "20px",
                          textAlign: "center",
                          flexGrow: 1,
                          color: "#2C73FF",
                        }}
                      >
                        {t("pages.title.send_a_campaign")}
                      </Typography>
                    </Box>
                    <Typography color="grey.600" mt={2} mb={2} variant="body2">
                      {`Send a campaign to ${campiagnModalData?.peopleCount} people`}
                    </Typography>
                    <Box>
                      <div>Select a message</div>
                      <TextField
                        select
                        // label="Message"
                        value={campiagnModalData?.message_id}
                        onChange={(e) => {
                          setCampiagnModalData({
                            ...campiagnModalData,
                            message_id: e.target.value,
                          });
                        }}
                        fullWidth
                        sx={{
                          mt: 1,
                          backgroundColor: "#F6F6F6",
                          mb: 2,
                          width: "500px",
                        }}
                      >
                        {messageData.map((group, index) => (
                          <MenuItem key={group.id} value={group.id}>
                            <span>
                              {group.title}
                            </span>
                          </MenuItem>
                        ))}
                      </TextField>

                      <div>Select the time interval</div>
                      <ToggleButtonGroup
                        className="btn-grooups"
                        value={campiagnModalData?.time_interval}
                        exclusive
                        onChange={(e) => {
                          setCampiagnModalData({
                            ...campiagnModalData,
                            time_interval: e.target.value,
                          });
                        }}
                        sx={{ margin: ".5rem 0", mb: 2 }}
                      >
                        <ToggleButton
                          value="2-4 Min"
                          disabled={campiagnModalData?.peopleCount > 10}
                        >
                          <span>FAST</span> 2-4 Min
                        </ToggleButton>
                        <ToggleButton value="4-6 Min">
                          <span>MEDIUM</span> 4-6 Min
                        </ToggleButton>
                        <ToggleButton value="6-10 Min">
                          <span>SLOW</span> 6-10 Min
                        </ToggleButton>
                        <ToggleButton value="10-15 Min">
                          <span>Very Slow</span>
                          10-15 Min
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <div>Select the next action </div>

                      <TextField
                        select
                        // label="Action"
                        value={campiagnModalData?.selectAction || false}
                        onChange={(e) => {
                          setCampiagnModalData({
                            ...campiagnModalData,
                            selectAction: e.target.value,
                          });
                          if (e.target.value === "Delete From Group") {
                            setCampiagnModalData({
                              ...campiagnModalData,
                              selectAction: e.target.value,
                              moveGroupId: null,
                              moveStageId: null,
                            });
                          }
                        }}
                        fullWidth
                        sx={{
                          mt: 1,
                          backgroundColor: "#F6F6F6",
                          mb: 2,
                        }}
                      >
                        <MenuItem value={false}>No Action</MenuItem>
                        <MenuItem value="Move To Stage">Move To Stage</MenuItem>
                        <MenuItem value="Move To Group">Move To Group</MenuItem>
                        <MenuItem value="Delete From Group">
                          Delete From Group
                        </MenuItem>
                      </TextField>
                      {campiagnModalData?.selectAction === "Move To Stage" ? (
                        <TextField
                          select
                          label="Stage"
                          value={campiagnModalData?.moveStageId}
                          onChange={(e) => {
                            setCampiagnModalData({
                              ...campiagnModalData,
                              moveStageId: e.target.value,
                              moveGroupId: groupUsers?.id,
                            });
                          }}
                          fullWidth
                        >
                          {groupUsers?.stage.map((group) => (
                            <MenuItem key={group.stage_num} value={group.id}>
                              {group.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      ) : (
                        ""
                      )}
                      {campiagnModalData?.selectAction === "Move To Group" ? (
                        <>
                          <TextField
                            select
                            label="Group"
                            value={campiagnModalData?.moveGroupId}
                            onChange={(e) => {
                              setCampiagnModalData({
                                ...campiagnModalData,
                                moveGroupId: e.target.value,
                              });
                            }}
                            fullWidth
                          >
                            {savedGroups.map((group) => (
                              <MenuItem key={group.id} value={group.id}>
                                {group.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          {campiagnModalData?.moveGroupId && (
                            <TextField
                              select
                              label="Group Stage"
                              value={campiagnModalData?.moveStageId}
                              onChange={(e) => {
                                setCampiagnModalData({
                                  ...campiagnModalData,
                                  moveStageId: e.target.value,
                                });
                              }}
                              fullWidth
                            >
                              {savedGroups
                                .find(
                                  (group) =>
                                    group.id === campiagnModalData?.moveGroupId
                                )
                                ?.stage.sort(
                                  (a, b) => a.stage_num - b.stage_num
                                )
                                .map((group) => (
                                  <MenuItem
                                    key={group.stage_num}
                                    value={group.id}
                                  >
                                    {group.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  </DialogContent>
                  <DialogActions sx={{ backgroundColor: "#F4F1FA" }}>
                    <button
                      attr-data={JSON.stringify(campiagnModalData)}
                      id="submit_insta_camping"
                      className="green-pop-btn"
                    >
                      <span>{t("pages.title.send")}</span>
                    </button>
                    <button
                      onClick={sendCampaignModal.onClose}
                      className="gray-pop-btn"
                    >
                      <span>{t("pages.title.cancel")}</span>
                    </button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </>
      )}
      {showCampign ? (
        <Campaign
          selectedgroup={campiagnData}
          filteredUsersByStage={filteredUsersByStage}
          stageName={stageName}
          setStageName={setStageName}
        />
      ) : (
        ""
      )}
      <InstagramExceedLimitModal
        exceedLimitModal={exceedLimitModal}
        title='You have reached your limit of "CRM Groups"'
        subtitle="Please upgrade your plan"
      />
    </>
  );
}
